import React from 'react';

import android1 from '../../images/android_1.png';
import android2 from '../../images/android_2.png';
import android3 from '../../images/android_3.png';
import ios1 from '../../images/ios_1.png';
import ios2 from '../../images/ios_2.png';
import ios3 from '../../images/ios_3.png';

const PwaManualContainerType = ({type, className} : {type?: string, className?: string}) => {
  const ios = type === 'ios';
  const title = ios ? 'Если у вас IPHONE' : 'Если у вас ANDROID';
  const steps = [
    {
      id: 1,
      label: 'Шаг 1',
      src: ios ? ios1 : android1,
      text: ios
        ? (
          <>
            Откройте <a href='https://zapodarkom.ru/#/' rel="noreferrer" target='_blank' className={'pwa-info__step-title'}>zapodarkom.ru</a> 
            {' '}в браузере Safari на вашем смартфоне. В меню браузера нажмите кнопку «Поделиться»
          </>
        )
        : (
          <>
            Откройте <a href='https://zapodarkom.ru/#/' rel="noreferrer" target='_blank' className={'pwa-info__step-title'}>zapodarkom.ru</a> 
            {' '}в браузере в браузере Chrome на вашем смартфоне
          </>
        ),
    },
    {
      id: 2,
      label: 'Шаг 2',
      src: ios ? ios2 : android2,
      text: ios
        ? 'В появившемся списке выберите пункт: На экран «Домой»'
        : 'Открыть боковую панель и нажать на скачать приложение',
    },
    {
      id: 3,
      label: 'Шаг 3',
      src: ios ? ios3 : android3,
      text: ios
        ? 'Нажмите «Добавить» и иконка появится на вашем смартфоне'
        : 'После установки на вашем смартфоне появится иконка «За!Подарком»',
    },
  ];

  return (
    <div className={`pwa-info__content ${className}`}>
      <h2 className={'pwa-info__title'}>{title}</h2>
      <ul className={'pwa-info__list'}>
        {steps.map(({ id, label, src, text }) => (
          <li key={id} className={'pwa-info__item'}>
            <img src={src} alt={label} className={'pwa-info__img'} />
            <h3 className={'pwa-info__step-title'}>{label}</h3>
            <p className={'pwa-info__step-text'}>{text}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PwaManualContainerType;
