export const prepareSymbolContent = (
  xml: Document,
  id: string,
  placeholderRef: React.RefObject<HTMLDivElement>,
  setSymbolContent: (content: string | null) => void,
  isMounted: React.MutableRefObject<boolean>
) => {
  if (!isMounted.current) return;

  const symbol = xml.getElementById(id);
  if (!symbol) return;

  let symbolHTML = symbol.innerHTML;
  const uniqueId = `gradient-${Math.random().toString(36)}`;
  // заменяем все id в defs на уникальные
  symbolHTML = symbolHTML.replace(/id="([^"]+)"/g, (_, match) => `id="${uniqueId}-${match}"`);
  // заменяем ссылки в fill='url' на новые id
  symbolHTML = symbolHTML.replace(/url\(#([^"]+)\)/g, (_, match) => `url(#${uniqueId}-${match})`);
  // удаление у всех тегов xmlns
  symbolHTML = symbolHTML.replace(/\sxmlns="http:\/\/www\.w3\.org\/2000\/svg"/g, '');

  const viewBox = symbol.getAttribute('viewBox') || '0 0 24 24';
  const [,, width, height] = viewBox.split(' ').map(Number);

  if (placeholderRef.current) {
    placeholderRef.current.style.aspectRatio = `${width} / ${height}`;
  }

  const newSvg = `
    <svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" viewBox="${viewBox}">
      ${symbolHTML}
    </svg>
  `;

  setSymbolContent(newSvg);
};