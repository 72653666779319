export enum StorageItemsNames {
    USER = 'user',
    TOKEN = 'token',
    REFRESH_TOKEN = 'refresh_token',
    CARD_NUMBER = 'cardNumber',
    TIMER = 'timer',
    PAST_TENSE = 'pastTense',
    TIME = 'time',
    PWA_STORAGE_KEY = 'pwa_state'
}
