import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

import {setActiveAvailability, setActiveCategory, setSearchFilter} from 'store/components/PrizesSlice';
import {useAppDispatch, useAppSelector, useResize} from 'utils/hooks';

import './TabBar.scss';

interface ITabBar {
  tabBar: {
    name: string,
    child: any,
    hide?: boolean,
    subtitle?: string,
    id: string,
  }[];
}

const TabBar: React.FC<ITabBar> = ({tabBar}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {pathname} = useLocation();
  const {width} = useResize();

  const categories = useAppSelector((state) => state.prizes.categories);
  const searchQuery = useAppSelector((state) => state.prizes.searchQuery);
  const availability = useAppSelector((state) => state.prizes.availability);
  const user = useAppSelector(state => state.auth.user);

  const id = pathname.slice(pathname.lastIndexOf('/') + 1);
  const isPlastic = !!user?.purses?.find(item => item.plastic_included);

  const [switchPage, setSwitchPage] = useState<boolean>(true);
  const [idChild, setIdChild] = useState<string>(id);

  const handleActiveItem = (id: string) => {
    history.push(id);
  };

  useEffect(() => {
    if (id !== idChild) {
      setSwitchPage(false);
      setIdChild(id);
      setTimeout(() => {
        setSwitchPage(true);
      }, 300);
    }
  }, [id]);

  useEffect(() => {
    if (searchQuery !== '' || !categories[0]?.active || !availability[0]?.active) {
      dispatch(setSearchFilter(''));
      categories.length && dispatch(setActiveCategory(0));
      dispatch(setActiveAvailability(0));
    }
  }, [id]);

  return (
    <div className={'tab-bar'} data-mobile-wrapper={id === 'profile'}>
      <div
        data-mobile-visible={(id === 'prizes' || id === 'plastic-prizes') && isPlastic && width < 631}
        className={'tab-bar__menu-container'}>
        <div
          className={
            id !== 'place-order'
              ? 'tab-bar__menu'
              : 'tab-bar__menu tab-bar__menu_hide'
          }>
          <div className={'tab-bar__container tab-bar__fixed-container'}>
            {tabBar.map((item) => {
              if (
                item.id !== 'prizes' &&
                item.id !== 'plastic-prizes' &&
                isPlastic &&
                width < 631
              ) {
                return;
              }

              return (
                !item.hide ? (
                  <span key={item.name} className={'tab-bar__item-container'}>
                    <div
                      onClick={() => handleActiveItem(item.id)}
                      className={
                        id === item.id
                          ? 'tab-bar__item tab-bar__item_active'
                          : 'tab-bar__item'
                      }>
                      {item.name}
                    </div>
                    <span className={'tab-bar__subtitle'}>{item.subtitle ? item.subtitle : null}</span>
                  </span>
                ) : null
              );
            })}
          </div>
        </div>
        <div className={'tab-bar__line'} data-hide={id === 'place-order'} />
      </div>
      <div className={'tab-bar__children tab-bar__fixed-container'}>
        <div className={'tab-bar__back'}>
          <div
            className={
              switchPage
                ? 'tab-bar__children-container tab-bar__children-container_active'
                : 'tab-bar__children-container'
            }>
            {tabBar.filter(item => item.id.includes(id))[0].child}
          </div>
        </div>

      </div>
    </div>
  );
};

export default TabBar;
