import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import env from 'env/env.json';

import ax from 'utils/ax';

import {INominal, setLoading} from './PrizesSlice';

export type ICartList = {
  nominal: number;
  amount: string | number;
  guid: string;
  name: string;
  is_reserved: boolean;
  id: number;
  checkbox: boolean;
  total: number;
  is_eps: boolean;
  validity_period: string;
  markup_nominal:number;
  tax?: number
}

export interface ICart {
  cart: ICartList[];
  total: number;
  total_markup?: number;
  total_tax?: number;

  normalizedData?: { [ket: string]: string };
}

export const updateInnerFunction = createAsyncThunk(
  'cart/updateInnerFunction',
  async (payload: any, {dispatch}) => {

    dispatch(setLoading({name: 'cartLoading', value: true}));
    const {data} = await ax().post('api/cart/update', payload.apiPayload);
    dispatch(setLoading({name: 'cartLoading', value: false}));

    const result = data.data.result[0];
    const changeCartForCorrectWork =
      result.cards
        .filter((cart: ICartList) => cart.amount)
        .map((itemCart: ICartList) => {
          return {
            ...itemCart,
            checkbox: false,
          };
        });

    return {
      cards: changeCartForCorrectWork,
      total: result.total,
      total_markup: result.total_markup,
      total_tax: result.total_tax
    };
  }
);

export const updateCart = createAsyncThunk(
  'cart/updateCart',
  async (payload, {getState, dispatch}) => {
    const {dashboard, prizes, cart}: any = getState();

    const nominals: {[key: string]: INominal}  = prizes.card.nominals;
    const changeCart = cart.cart.map((cartItem: ICartList) => {
      return {
        nominal: cartItem.nominal,
        guid: cartItem.guid,
        amount: +cartItem.amount,
        is_reserved: !!cartItem.is_reserved,
        tax: cartItem.tax
      };
    });

    const list = Object.values(nominals)
      .filter((item) => item.count !== 0)
      .map((card) => {
        return {
          nominal: +card.nominal,
          guid: prizes.card.guid_1c,
          amount: +card.count,
          is_reserved: card.qty > 0,
        };
      });

    const cards = [...changeCart, ...list].reduce((acc, item) => {
      const oldItem = acc.find((oldItem: any) =>
        oldItem.nominal === item.nominal
        && oldItem.is_reserved === item.is_reserved
        && oldItem.guid === item.guid)
      ;
      if (oldItem) {
        oldItem.amount = ''+((+oldItem.amount)+(+item.amount));}
      else {
        acc.push(item);
      }
      return acc;
    },
    []);

    const reserveCardsList = {
      purse_id: dashboard.purse.id,
      cards: cards,
    };

    try {
      dispatch(updateInnerFunction({apiPayload: reserveCardsList}));
    } catch (e) {
      console.error(e);
    }
  }
);

export const getCart = createAsyncThunk(
  'cart/getCart',
  async (payload, {getState, dispatch}) => {
    const {dashboard}: any = getState();

    if (!dashboard.purse?.id) {
      return;
    }
    try {
      await dispatch(setLoading({name: 'cartLoading', value: true}));
      const {data} = await ax().post('api/cart/get', {purse_id: dashboard.purse?.id});
      const code = data.data.code;
      await dispatch(setLoading({name: 'cartLoading', value: false}));
      await dispatch(setLoading({name: 'pursesLoading', value: false}));

      if (code === 'error') {
        console.log(code);
      } else {
        const result = data.data.result;
        const changeCartForCorrectWork =
          result.cards
            .filter((cart: ICartList) => cart.amount)
            .map((itemCart: ICartList) => {
              return {
                ...itemCart,
                checkbox: false,
              };
            });

        return {
          cards: changeCartForCorrectWork,
          total: result.total,
          total_markup: result.total_markup,
          total_tax: result.total_tax
        };
      }
    } catch (e) {
      console.error(e);
      await dispatch(setLoading({name: 'cartLoading', value: false}));
    }
  }
);

export const changeAmountCart = createAsyncThunk(
  'cart/changeAmountCart',
  async (payload: {id: number, mode: string, error: any}, {getState, dispatch}) => {
    const {cart, dashboard}: any = getState();

    const changeCart = cart.cart.map((cartItem: ICartList) => {
      if (cartItem.id === payload.id) {
        if (payload.mode === 'minus') {
          if (+cartItem.amount - 1 === -1) {
            return cartItem;
          }
          return {...cartItem, amount: +cartItem.amount - 1};
        } else {
          return {...cartItem, amount: +cartItem.amount + 1};
        }
      }

      return cartItem;
    });

    const apiPayload  = {
      purse_id: dashboard.purse.id,
      cards: changeCart,
    };

    try {
      dispatch(updateInnerFunction({apiPayload}))
        .then((res) => {
          if (res.payload === undefined) {
            payload.error();
          }
        });
    } catch (e) {
      console.error(e);
      await dispatch(setLoading({name: 'cartLoading', value: false}));
    }
  }
);

export const onChangeAmountForInput = createAsyncThunk(
  'cart/onChangeAmountForInput',
  async (payload: {id: number, amount: number, error?: any}, {getState, dispatch}) => {
    const {cart, dashboard}: any = getState();

    const changeCart = cart.cart.map((cartItem: ICartList) => {
      if (cartItem.id === payload.id) {
        return {...cartItem, amount: payload.amount};
      }

      return cartItem;
    });

    const apiPayload  = {
      purse_id: dashboard.purse.id,
      cards: changeCart,
    };

    try {
      dispatch(updateInnerFunction({apiPayload}))
        .then((res) => {
          if (res.payload === undefined) {
            payload.error();
          }
        });
    } catch (e) {
      console.error(e);
      await dispatch(setLoading({name: 'cartLoading', value: false}));
    }
  }
);

export const deleteItemCart = createAsyncThunk(
  'cart/deleteItemCart',
  async (payload: {id: number}, {getState, dispatch}) => {
    const {cart, dashboard}: any = getState();

    const changeCart = cart.cart.map((cartItem: ICartList) => {
      if (cartItem.id === payload.id) {
        return {...cartItem, amount: 0};
      } else {
        return cartItem;
      }
    });

    const apiPayload  = {
      purse_id: dashboard.purse.id,
      cards: changeCart,
    };

    try {
      dispatch(updateInnerFunction({apiPayload}));
    } catch (e) {
      console.error(e);
      await dispatch(setLoading({name: 'cartLoading', value: false}));
    }
  }
);

export const multipleDeleteItemCart = createAsyncThunk(
  'cart/multipleDeleteItemCart',
  async (payload, {getState, dispatch}) => {

    const {cart, dashboard}: any = getState();

    const changeCart = cart.cart.map((cartItem: ICartList) => {
      if (cartItem.checkbox) {
        return {...cartItem, amount: 0};
      } else {
        return cartItem;
      }
    });

    const apiPayload  = {
      purse_id: dashboard.purse.id,
      cards: changeCart,
    };

    try {
      dispatch(updateInnerFunction({apiPayload}));
    } catch (e) {
      console.error(e);
      await dispatch(setLoading({name: 'cartLoading', value: false}));
    }
  }
);

export const clearCart = createAsyncThunk(
  'cart/clearCart',
  async (payload, {getState, dispatch}) => {

    const {cart, dashboard}: any = getState();

    const changeCart = cart.cart.map((cartItem: ICartList) => {
      return {...cartItem, amount: 0};
    });

    const apiPayload  = {
      purse_id: dashboard.purse.id,
      cards: changeCart,
    };

    try {
      dispatch(updateInnerFunction({dispatch, apiPayload}));
    } catch (e) {
      console.error(e);
      await dispatch(setLoading({name: 'cartLoading', value: false}));
    }
  }
);

const CartSlice = createSlice({
  name: 'cart',
  initialState: {
    cart: [],
    total: 0,
  },
  reducers: {
    changeCheckbox: (state: ICart, {payload}) => {
      const changeArray: any = state.cart.map((cartItem) => {
        if (cartItem.id === payload) {
          return {...cartItem, checkbox: !cartItem.checkbox};
        } else {
          return cartItem;
        }
      });

      state.cart = changeArray;
    },
    allSetCheckboxInCart: (state: ICart, {payload}) => {
      state.cart = state.cart.map((cartItem: ICartList) => {
        return {...cartItem, checkbox: payload};
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCart.fulfilled, (state: ICart, action) => {
      if (action.payload) {
        state.normalizedData = action.payload.cards.reduce((acc: {[key: string]: string}, item: ICartList) => {
          acc[item.guid] = item.guid;
          return acc;
        }, {});
        state.cart = action.payload.cards;
        state.total = action.payload.total;
        state.total_markup = action.payload.total_markup;
        state.total_tax = action.payload.total_tax;
      }
    });
    builder.addCase(updateInnerFunction.fulfilled, (state: ICart, action) => {
      if (action.payload) {
        state.cart = action.payload.cards;
        state.total = action.payload.total;
        state.total_markup = action.payload.total_markup;
        state.total_tax = action.payload.total_tax;
      }
    });
  },
});

export const {changeCheckbox, allSetCheckboxInCart} = CartSlice.actions;

export default CartSlice.reducer;
