import {createSlice} from '@reduxjs/toolkit';

interface BeforeInstallPromptEvent extends Event {
  prompt: () => Promise<void>;
  userChoice: Promise<{ outcome: 'accepted' | 'dismissed'; platform: string }>;
}

export interface PwaState {
  deferredPrompt: BeforeInstallPromptEvent | null; // Тип события beforeinstallprompt
  isAutoTriggered: boolean;
}

const initialState: PwaState = {
  deferredPrompt: null,
  isAutoTriggered: false,
};

const PwaSlice = createSlice({
  name: 'pwa',
  initialState,
  reducers: {
    setDeferredPrompt(state, action) {
      state.deferredPrompt = action.payload;
    },
    clearDeferredPrompt(state) {
      state.deferredPrompt = null;
    },
    setIsAutoTriggered(state, action) {
      state.isAutoTriggered = action.payload;
    },
  },
});

export const { setDeferredPrompt, clearDeferredPrompt, setIsAutoTriggered } = PwaSlice.actions;

export default PwaSlice.reducer;