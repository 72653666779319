import React, {createRef, useEffect, useRef, useState} from 'react';

import {useAppDispatch, useAppSelector} from 'utils/hooks';
import {findLink, lazyLoading} from 'utils/functions';

import {showPopup} from 'store/components/PopupSlice';
import {
  ICardData,
  setCard,
  addMarkupsToCards,
  getNominals, setActiveCategory, setLoading, setChangeSearchData,
} from 'store/components/PrizesSlice';
import {GetIcon} from 'store/enums/normalizedIcons';

import Button from 'components/button/Button';
import UpwardButton from 'components/upwardButton/UpwardButton';
import WindowLoading from 'components/windowLoading/WindowLoading';

import './Cards.scss';

const Cards = ({step, setStep, mode = 'virtual'}: {step: any, setStep: any, mode?: string}) => {
  const dispatch = useAppDispatch();

  const liRef = useRef<any>();
  const observerLoader = useRef<any>();
  const lastItem = createRef<any>();
  const cards = useAppSelector(state => state.prizes.cards);
  const cardsTotal = useAppSelector(state => state.prizes.cardsTotal);
  const markups = useAppSelector(state => state.prizes.markups);
  const cart = useAppSelector(state => state.cart.normalizedData);
  const categories = useAppSelector(state => state.prizes.categories);
  const loading = useAppSelector(state => state.prizes.loading);

  const colors = useAppSelector((state) => state.settings.colors);
  const domain = useAppSelector((state) => state.settings.currentDomainName);

  const [scroll, setScroll] = useState<number>(0);

  const enabledNewCard = Object.values(cards).length ? Object.keys(cards).length < cardsTotal : false;
  const {loadButtonColor} = colors[domain];

  useEffect(() => {
    lazyLoading(lastItem, observerLoader, setScroll, 12);
  }, [lastItem]);

  useEffect(() => {
    if (liRef.current?.offsetTop <= scroll && !loading['prizes']) {
      setStep(step + 1);
    }
  }, [scroll]);

  window.addEventListener('scroll', () => {
    setScroll(window.pageYOffset + window.innerHeight);
  });

  const showOrderCard = async (guid: string) => {
    await dispatch(setCard(guid));
    await dispatch(getNominals(guid));
    await dispatch(showPopup('orderCard'));
  };

  const clickCategory = (event: any, category: string) => {
    const index = categories.findIndex((item) => item.name === category);
    dispatch(setChangeSearchData(true));
    setTimeout(() => {
      dispatch(setChangeSearchData(false));
    }, 0);
    if (index > 0) {
      dispatch(setActiveCategory(index));
    }
    event.stopPropagation();
  };

  useEffect(() => {
    if (!loading['prizes'] && !loading['markups'] && cards && Object.keys(cards).length && Object.keys(markups).length) {
      dispatch(addMarkupsToCards());
    }
  },[loading['markups']]);

  return (
    <div className={'prizes-cards'}>
      <WindowLoading id={'prizes'}/>
      <ul
        className={'prizes-cards__list'}>
        {
          Object.values(cards).length ?
            Object.values(cards).map((card_data: ICardData, index) => {
              const in_cart = cart && cart[card_data.guid_1c];

              return card_data ? (
                <li
                  ref={index === ((step + 1) * 12) - 1 ? liRef : null}
                  key={card_data.id}
                  onClick={() => showOrderCard(card_data.guid_1c)}
                  className={'prizes-cards__item prizes-cards__item_active'}>
                  <div className={'prizes-cards__item-container'}>
                    <div>
                      <div className={'prizes-cards__image-container'}>
                        {mode === 'plastic' ? <span className={'prizes-cards__plastic-tip'}>Пластик</span> : null}
                        <img src={card_data.file_link} alt='cards-logo' className={'prizes-cards__image'}/>
                      </div>
                      <div className={'prizes-cards__content'}>
                        <h3 className={'prizes-cards__title'}>{card_data.name}</h3>
                      </div>
                    </div>
                    <div className={'prizes-cards__content'} onClick={e => e.stopPropagation()}>
                      <p className={'prizes-cards__subtitle prizes-cards__subtitle_desktop'}>Категория:&nbsp;
                        <span
                          onClick={(event) => clickCategory(event, card_data.category)}
                          className={'prizes-cards__subtitle prizes-cards__category'}>
                          {card_data.category}
                        </span>
                      </p>
                      <Button
                        width={'100%'}
                        className={`prizes-cards__button ${in_cart || card_data.in_cart ? 'prizes-cards__button-icon' : ''}`}
                        onClick={() => showOrderCard(card_data.guid_1c)}>
                        {in_cart || card_data.in_cart ? <GetIcon iconName={'InCart'} fill={colors[domain].firstColorIconActive} /> : 'Выбрать номинал'}
                      </Button>
                    </div>
                  </div>
                  <div className={'prizes-cards__button-container'} onClick={e => e.stopPropagation()}>
                    <p className={'prizes-cards__subtitle prizes-cards__subtitle_mobile '}>Категория:&nbsp;
                      <span
                        onClick={(event) => clickCategory(event, card_data.category)}
                        className={'prizes-cards__subtitle prizes-cards__category'}>
                        {card_data.category}
                      </span>
                    </p>
                    <Button
                      borderRadius={5}
                      className={`
                        prizes-cards__button
                        prizes-cards__button_mobile
                        ${in_cart || card_data.in_cart ? 'prizes-cards__button-icon' : ''}
                      `}
                      onClick={() => showOrderCard(card_data.guid_1c)}>
                      {in_cart || card_data.in_cart ? <GetIcon iconName={'InCart'} fill={colors[domain].firstColorIconActive}  /> : 'Выбрать номинал'}
                    </Button>
                  </div>
                </li>
              ) : null;
            }) : !loading['prizes'] ? (
              <h3 className={'prizes-cards__nothing'}>
                  Не удалось найти сертификаты
              </h3>
            ) : null

        }
      </ul>
      {
        enabledNewCard
          ? (
            <div className={'prizes-cards__button-container'}>
              <Button
                disabled={!enabledNewCard}
                className={'load-button'}
                icon={<GetIcon iconName={'LoadButton'} fill={loadButtonColor} secondaryFill={loadButtonColor} />}
                onClick={() => setStep(step + 1)}>
                Показать еще
              </Button>
            </div>
          ) : null
      }
      <UpwardButton />
    </div>
  );
};

export default Cards;
