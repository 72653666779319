import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {useAppSelector, useMediaQuery} from 'utils/hooks';
import {closeModal} from 'utils/functions';

import {hidePopup, showPopup} from 'store/components/PopupSlice';
import {GetIcon} from '../../../../../store/enums/normalizedIcons';

import Popup from 'components/popup/Popup';
import Tip from 'components/tip/Tip';
import Button from 'components/button/Button';

import question from './assets/images/question.svg';
import amulet from './assets/images/amulet.svg';

import './CardsOver.scss';

const CardsOver = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useMediaQuery(768);

  const card_data = useAppSelector(state => state.prizes.card);
  const colors = useAppSelector(state => state.settings.colors);
  const domain = useAppSelector(state => state.settings.currentDomainName);

  const [visibleTip, setVisibleTip] = useState<boolean>(false);

  const onMouseOver = () => {
    setVisibleTip(true);
  };

  const onMouseOut = () => {
    setVisibleTip(false);
  };

  const backToOrderCard = () => {
    dispatch(hidePopup('cardsOver'));
    dispatch(showPopup('orderCard'));
  };

  const goToOrder = () => {
    dispatch(hidePopup('cardsOver'));
    history.push('cart');
    closeModal();
  };

  if (!card_data) {
    return null;
  }

  return (
    <Popup 
      id={'cardsOver'}
      mode={`${isMobile ? 'mobile_bottom-full' : 'classic'}`} 
      stylesContent={'cards-over__popup-content'}>
      <div className={'cards-over'}>
        <img src={amulet} alt="amulet" className={'cards-over__amulet'}/>
        <div className={'cards-over__description'}>
          Пока вы выбирали, некоторые сертификаты «{card_data.name}» из наличия –{' '}
          <div
            className={'cards-over__tip-container'}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}>
            <span className={'cards-over__description_uppercase'}>закончились!</span>
            <span className={'cards-over__question'}>
              <GetIcon
                iconName={'InfoIcon'}
                fill={colors[domain].infoIcon}
                secondaryFill={colors[domain].infoIcon}
                width={'16'}
              />
            </span>
            <img src={question} alt="question" className={'cards-over__question'}/>
            <Tip visible={visibleTip} className={'cards-over__tip-position'}>
              Информация о наличии обновляется каждые 5 минут
            </Tip>
          </div>
        </div>
        <Button
          backgroundColor={'#E8EBF6'}
          width={'100%'}
          className={'cards-over__button-back'}
          onClick={backToOrderCard}
          borderRadius={8}
          marginBottom={8}
          uppercase={false}>
          вернуться назад в карточку эмитента
        </Button>
        <Button
          onClick={goToOrder}
          backgroundColor={'#4661C4'}
          borderRadius={8}
          className={'cards-over__button-to-order'}
          width={'100%'}
          uppercase={false}>
          все равно хочу добавить заказ в корзину, подожду
        </Button>
      </div>
    </Popup>
  );
};

export default CardsOver;
