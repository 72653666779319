import React from 'react';

import {useMediaQuery} from 'utils/hooks';

import Popup from '../popup/Popup';

import warning from './assets/images/warning.svg';

import './TooManyAttempts.scss';

const TooManyAttempts = () => {
  const isMobile = useMediaQuery(768);
  
  return (
    <Popup 
      id={'tooManyAttempts'} 
      mode={`${isMobile ? 'mobile_bottom-full' : 'classic'}`} 
      stylesContent={'too-many-attempts'}>
      <div className={'too-many-attempts__container'}>
        <img src={warning} alt="warning" className={'too-many-attempts__icon'} />
        <h1 className={'too-many-attempts__title'}>
          Вы уже скачали сертификат
        </h1>
        <h4 className={'too-many-attempts__text'}>
          Попробуйте повторить попытку позже
        </h4>
      </div>
    </Popup>
  );
};

export default TooManyAttempts;
