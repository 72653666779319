import {useCallback, useEffect} from 'react';

interface UseCenterActiveTabProps {
  containerRef: React.RefObject<HTMLDivElement>;
  tabRefs: React.MutableRefObject<{ [key: number]: HTMLDivElement | null }>;
  activeTab: number;
}

const useCenterActiveTab = ({ containerRef, tabRefs, activeTab }: UseCenterActiveTabProps) => {
  const centerActiveTab = useCallback(() => {
    if (
      activeTab >= 0 &&
      tabRefs.current[activeTab] &&
      containerRef.current
    ) {
      const selectedTab = tabRefs.current[activeTab] as HTMLDivElement;
      const container = containerRef.current;

      const offsetLeft = selectedTab.offsetLeft;
      const offsetWidth = selectedTab.offsetWidth;
      const containerWidth = container.offsetWidth;

      const newScrollLeft = offsetLeft - (containerWidth - offsetWidth) / 2;
      container.scrollLeft = newScrollLeft;
    }
  }, [activeTab, containerRef, tabRefs]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      centerActiveTab();
    }, 0);

    return () => clearTimeout(timeoutId);
  }, [centerActiveTab]);
};

export default useCenterActiveTab;