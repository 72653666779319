import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {StorageItemsNames} from 'enums';
import env from 'env/env.json';
import {IStore} from 'store/store';
import {useMediaQuery} from 'utils/hooks';
import {closeModal} from 'utils/functions';
import {hidePopup, showPopup} from 'store/components/PopupSlice';
import Popup from 'components/popup/Popup';
import Timer from 'components/timer/Timer';

import {verifiedEmailTypeContent} from './assets/utils/verifeidEmailType';
import VerifiedEmailMessage from './assets/components/VerifiedEmailMessage';

import './CompletedVerifiedEmail.scss';

const CompletedVerifiedEmail = () => {
  const dispatch = useDispatch();
  const {search, pathname} = useLocation();
  const isMobile = useMediaQuery(768);
  
  const completedVerifiedEmail =
    useSelector((state: IStore) => state.popup.popup.completedVerifiedEmail);
  
  const href = window.location.href;
  const getUser = localStorage.getItem(StorageItemsNames.USER);
  const splitUrl = search.split('=')[1];
  const {icon, iconProps, text, className} = verifiedEmailTypeContent[splitUrl] || verifiedEmailTypeContent.default;
  const popupQueryType = [
    'unableToConfirm',
    'verifiedEmail',
    'alreadyConfirmed',
    'invalidEmail',
  ];

  const autoCloseTab = () => {
    if (getUser && href.includes('dashboard')) {
      if (href.includes(splitUrl)) {
        window.location.href = env.urlSite + `${pathname.replace('/', '')}`;
      }
    } else {
      window.location.href = env.urlSite;
    }

    closeModal();
  };

  useEffect(() => {
    if (popupQueryType.includes(splitUrl)) {
      dispatch(showPopup('completedVerifiedEmail'));
    } else {
      dispatch(hidePopup('completedVerifiedEmail'));
    }
  }, []);

  return (
    <Popup 
      id={'completedVerifiedEmail'} 
      mode={`${isMobile ? 'mobile_bottom-full' : 'classic'}`} 
      onClose={autoCloseTab}
      stylesContent={'completed-verified-email__content'}>
      <div className={'completed-verified-email'}>
        <VerifiedEmailMessage icon={icon} iconProps={iconProps} text={text} className={className} />
        <Timer popupStatus={{completedVerifiedEmail}} onClose={autoCloseTab} />
      </div>
    </Popup>
  );
};

export default CompletedVerifiedEmail;
