import {Dispatch, SetStateAction} from 'react';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import ax from 'utils/ax';
import {INDFL, IPurse, setUser} from './AuthSlice';
import {setPurse} from './DashboardSlice';

import {StorageItemsNames} from 'enums';

export interface IProfile {
  email_verified_res: string;
  error_message: string;
}

export const updateProfile = createAsyncThunk(
  'profile/updateProfile',
  async ({phone, surname, name, email, payloadSetState, code, codeWithApi, is_required_email}: {
    phone: string;
    name: string;
    surname: string;
    is_required_email: boolean;
    email: string;
    code: string;
    codeWithApi: string;
    payloadSetState?: {
      setVisibleInputCode: Dispatch<SetStateAction<boolean>>;
      setCodeWithApi: Dispatch<SetStateAction<string>>;
      setErrorPhone: Dispatch<SetStateAction<string>>;
      setErrorEmail: Dispatch<SetStateAction<string>>;
      setNameError: Dispatch<SetStateAction<string>>;
      setSecondNameError: Dispatch<SetStateAction<string>>;
    }
  }, {getState, dispatch}) => {
    const {auth}: any = getState();
    try {
      const {data} = await ax().post('/api/user/update', {
        phone,
        name,
        surname,
        email,
        id: auth.user.id,
        is_required_email,
        is_new_phone_validated: code === codeWithApi && (code && codeWithApi) !== ''
      });

      const result = data.data.result;

      if (code === codeWithApi && (code && codeWithApi) !== '') {
        payloadSetState
        && payloadSetState.setVisibleInputCode(false);
      } else if (result[0].code) {
        payloadSetState
        && payloadSetState.setVisibleInputCode(true);
        payloadSetState
        && payloadSetState.setCodeWithApi(result[0].code.toString());
      }

      await dispatch(getUser());

      if (email !== auth.user.email && is_required_email) {
        await dispatch(sendEmailVerified());
      }

      return result[0];
    } catch (e: any) {
      const result = e.response.data.data.result;
      if (Object.keys(result).includes('phone')) {
        payloadSetState && payloadSetState.setErrorPhone(result.phone);
      }

      if (Object.keys(result).includes('email')) {
        payloadSetState && payloadSetState.setErrorEmail(result.email);
      }

      if (Object.keys(result).includes('name')) {
        payloadSetState && payloadSetState.setNameError(result.name[0]);
      }

      if (Object.keys(result).includes('surname')) {
        payloadSetState && payloadSetState.setSecondNameError(result.surname[0]);
      }
    }
  }
);

export const requestOnChangeUserData = createAsyncThunk(
  'profile/requestOnChangePhone',
  async ({changedData, type}: {changedData: string, type: string}) => {

    const reqData: any = {};
    reqData[type] = changedData;

    try {
      const data = await ax().post(
        `/api/user/request-on-change-${type}`,
        reqData);
      return data;
    } catch (e: any) {
      return e.response;
    }
  }
);

export const requestOnSendCode = createAsyncThunk(
  'profile/requestOnSendCode',
  async ({code, type}: {code: string, type: string}) => {

    const reqData = {code: code};

    const data = await ax().post(
      `/api/user/change-${type}`,
      reqData);
    return data;
  }
);

export const requestOnRepeatCode = createAsyncThunk(
  'profile/requestOnRepeatCode',
  async ({send_type, type}: {send_type: string, type: string}) => {

    const reqData = {send_type: send_type};

    const data = await ax().post(
      `/api/user/change-${type}/repeat-code`,
      reqData);
    return data;
  }
);

export const getUser = createAsyncThunk(
  'profile/getUser',
  async (payload, {getState, dispatch}) => {
    const {auth, dashboard}: any = getState();

    const {data} = await ax().post('/api/user/get', {user_id: auth.user.id});

    if (!data.data.result.user.email){
      data.data.result.user.email = '';
    }

    await localStorage.setItem(StorageItemsNames.USER, JSON.stringify(data.data.result.user));
    const userStorage = await JSON
      .parse(localStorage.getItem(StorageItemsNames.USER) || '{}');

    const findCurrentPurse =
      dashboard.purse
      && userStorage.purses.find((item: IPurse) => item.id === dashboard.purse.id);

    !dashboard.purse || !findCurrentPurse
      ? dispatch(setPurse(userStorage.purses[0]))
      : dispatch(setPurse(findCurrentPurse));

    await dispatch(setUser(userStorage));
  }
);

export const sendEmailVerified = createAsyncThunk(
  'profile/sendEmailVerified',
  async (payload: {id: string} | undefined, {getState}) => {
    const {auth}: any = getState();
    try {
      return await ax().post('/api/user/send-email-verify', {user_id: payload?.id || auth.user.id});
    } catch (e) {
      console.error(e);
      return 'error';
    }
  }
);

const ProfileSlice = createSlice({
  name: 'profile',
  initialState: {
    email_verified_res: 'pending',
    error_message: '',
  },

  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sendEmailVerified.fulfilled, (state: IProfile, {payload}: any) => {
      if (payload.data.data.code) {
        const result = payload.data.data.result;
        state.email_verified_res = payload.data.data.code;
        
        if (result && typeof result === 'string') {
          state.error_message = payload.data.data.result;
        }
      }
    });
    builder.addCase(sendEmailVerified.pending, (state: IProfile) => {
      state.email_verified_res = 'pending';
    });
    builder.addCase(sendEmailVerified.rejected, (state: IProfile) => {
      state.email_verified_res = 'error';
    });
  }
});

export default ProfileSlice.reducer;
