import {StorageItemsNames} from 'enums';

interface PwaState {
  isPwaInstalled: boolean; // Установлено ли PWA
  popupClosedAt: number | null; // Время последнего закрытия автоматического поп-апа
  autoCloseCount: number; // Счетчик закрытий автоматического поп-апа
}

export const getPwaState = (): PwaState => {
  const defaultState: PwaState = {
    isPwaInstalled: false,
    popupClosedAt: null,
    autoCloseCount: 0,
  };

  try {
    const stored = localStorage.getItem(StorageItemsNames.PWA_STORAGE_KEY);
    return stored ? JSON.parse(stored) : defaultState;
  } catch (error) {
    console.error('Ошибка чтения из localStorage:', error);
    return defaultState;
  }
};

export const setPwaState = (state: Partial<PwaState>) => {
  try {
    const currentState = getPwaState();
    const newState = { ...currentState, ...state };
    localStorage.setItem(StorageItemsNames.PWA_STORAGE_KEY, JSON.stringify(newState));
  } catch (error) {
    console.error('Ошибка записи в localStorage:', error);
  }
};

export const shouldShowPwaPopup = (): boolean => {
  const state = getPwaState();

  if (state.isPwaInstalled) return false;

  if (state.autoCloseCount >= 3) return false;

  if (!state.popupClosedAt) {
    return true;
  }

  const ONE_WEEK_MS = 7 * 24 * 60 * 60 * 1000; // 1 неделя в миллисекундах
  const now = Date.now();
  const timeSinceClosed = now - state.popupClosedAt;

  return timeSinceClosed >= ONE_WEEK_MS;
};