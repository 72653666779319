import React from 'react';

import PwaManualContainerType from '../PwaManualContainerType/PwaManualContainerType';

interface IHelpItem {question: string, answer: string}
interface IHelpList {[key: string]: IHelpItem[]}

import './PwaManual.scss';

const PwaManual = ({pwaQuestions} : {pwaQuestions: IHelpList | null}) => {
  return (
    <div>
      {Object.entries(pwaQuestions ? pwaQuestions : {})?.map((item, parentIndex) => {
        return (
          <div key={parentIndex} className={'pwa-info__container'}>
            <p className={'pwa-info__main-text'}>Мы используем технологию PWA — это технология, которая визуально и функционально преобразует сайт в приложение. Пользователь может загрузить его в смартфон как обычное мобильное приложение: получается «продвинутая версия сайта» с адаптацией под Android и iOS-устройства пользователей. PWA-версия не требует обновления.</p>
            <PwaManualContainerType type={'ios'} className={'pwa-info__ios-content'} />
            <PwaManualContainerType />
          </div>
        );
      })}
    </div>
  );
};

export default PwaManual;
