export const verifiedEmailTypeContent: Record<string, { icon: string; iconProps?: any; text: string, className?: string }> = {
  unableToConfirm: {
    icon: 'Cross',
    iconProps: {secondaryFill: 'red'},
    text: 'Ошибка, невозможно подтвердить почту',
  },
  alreadyConfirmed: {
    icon: 'Cross',
    iconProps: {secondaryFill: 'red'},
    text: 'Ваша почта уже подтверждена',
  },
  invalidEmail: {
    icon: 'Cross',
    iconProps: {secondaryFill: 'red'},
    text: 'Почта не может быть подтверждена. Пожалуйста, попробуйте другую',
  },
  default: {
    icon: 'LetterVerified',
    iconProps: {fill: '#27AE60', secondaryFill: '#27AE60'},
    className: 'completed-verified-email__verified-email',
    text: 'Спасибо! Ваш e-mail подтвержден',
  },
};
