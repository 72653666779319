import React from 'react';
import {
  Logo,
  Burger,
  Tile,
  Cart,
  Help,
  User,
  Card,
  LogOutIcon,
  CubeMenu,
  BurgerMainMenu,
  CartAuthMenu,
  BurgerMini,
  SettingIcon,
  LoadButton,
  InfoIcon,
  MiniLogoWhite,
  UpwardIcon,
  ArrowLink,
  ClipBoard,
  StatusCard,
  Cross,
  LetterVerified,
  LetterNoVerified,
  SelectStepNominal,
  Pencil,
  RaifUser,
  RaifCard,
  RaifCart,
  RaifSettingIcon,
  RaifCartAuthMenu,
  RaifHelp,
  RaifHistory,
  RaifTile,
  RaifLogOutIcon,
  RaifBurgerMainMenu,
  RaifLetterVerified,
  RaifCross,
  Check,
  RaifCheck,
  InCart,
  RaifInCart,
  ArrowLeft,
  HelpNew,
  LogoOpacity,
  AcceptStatusIcon,
  RejectStatusIcon,
  WaitingStatusIcon,
  WarningIcon,
  ChevronIcon,
  PlasticInfoIcon,
  PlasticCardIcon,
  QuestionPurseIcon,
  NDFLPurseIcon,
} from 'assets/images/IconPack';
import {store} from '../store';

export const appIcons = {
  default: {
    ChevronIcon: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <ChevronIcon
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    CartAuthMenu: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <CartAuthMenu
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    AcceptStatusIcon: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <AcceptStatusIcon
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    RejectStatusIcon: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <RejectStatusIcon
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    WaitingStatusIcon: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <WaitingStatusIcon
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    WarningIcon: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <WarningIcon
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    ArrowLeft: function Icon ({opacity = '0.3', className, secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <ArrowLeft
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
        className={className}
      />;
    },
    BurgerMainMenu: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <BurgerMainMenu
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    History: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <BurgerMainMenu
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    MiniLogoWhite: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <MiniLogoWhite
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    User: function Icon ({opacity = '0.3', className, secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <User
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
        className={className}
      />;
    },
    Logo: function Icon ({opacity = '0.3', className, secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <Logo
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
        className={className}
      />;
    },
    LogoOpacity: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <LogoOpacity
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    LoadButton: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <LoadButton
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    Burger: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <Burger
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    Tile: function Icon ({opacity = '0.3', className, secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <Tile
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
        className={className}
      />;
    },
    PlasticCard: function Icon ({fill = '#fff', secondaryFill = '#fff'}) {
      return <PlasticCardIcon
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    Cart: function Icon ({opacity = '0.3', className, secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <Cart
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
        className={className}
      />;
    },
    HelpNew: function Icon ({opacity = '0.3', className, secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <HelpNew
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
        className={className}
      />;
    },
    Help: function Icon ({opacity = '0.3', className, secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <Help
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
        className={className}
      />;
    },
    Card: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <Card
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    LogOutIcon: function Icon ({opacity = '0.3', className, secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <LogOutIcon
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
        className={className}
      />;
    },
    CubeMenu: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <CubeMenu
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    BurgerMini: function Icon ({opacity = '0.3', className, secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <BurgerMini
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
        className={className}
      />;
    },
    SettingIcon: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <SettingIcon
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    InfoIcon: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff', width}) {
      return <InfoIcon
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
        width={width}
      />;
    },
    UpwardIcon: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <UpwardIcon
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    ArrowLink: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <ArrowLink
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    ClipBoard: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <ClipBoard
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    StatusCard: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <StatusCard
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    Cross: function Icon ({opacity = '0.3', className, secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <Cross
        opacity={opacity}
        className={className}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    Check: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <Check
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    InCart: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <InCart
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    LetterVerified: function Icon ({opacity = '0.3', secondaryOpacity = '1', className, fill = '#fff', secondaryFill = '#fff'}) {
      return <LetterVerified
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
        className={className}
      />;
    },
    LetterNoVerified: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <LetterNoVerified
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },

    SelectStepNominal: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <SelectStepNominal
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    Pencil: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <Pencil
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    PlasticInfo: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <PlasticInfoIcon
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    QuestionPurse: function Icon ({fill = '#fff'}) {
      return <QuestionPurseIcon fill={fill} />;
    },
    NDFLIconPurse: function Icon ({fill = '#fff'}) {
      return <NDFLPurseIcon fill={fill} />;
    },
  },
  raiffeisen: {
    CartAuthMenu: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <RaifCartAuthMenu
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    BurgerMainMenu: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <RaifBurgerMainMenu
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    History: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <RaifHistory
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    MiniLogoWhite: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <MiniLogoWhite
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    User: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <RaifUser
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    Logo: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <Logo
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    LoadButton: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <LoadButton
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    Burger: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <Burger
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    Tile: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <RaifTile
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    Cart: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <RaifCart
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    Help: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <RaifHelp
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    Card: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <RaifCard
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    LogOutIcon: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <RaifLogOutIcon
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    CubeMenu: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <CubeMenu
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    BurgerMini: function Icon ({opacity = '0.3', className, secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <BurgerMini
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
        className={className}
      />;
    },
    SettingIcon: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <RaifSettingIcon
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    InfoIcon: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff', width}) {
      return <InfoIcon
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
        width={width}
      />;
    },
    UpwardIcon: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <UpwardIcon
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    ArrowLink: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <ArrowLink
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    ClipBoard: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <ClipBoard
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    StatusCard: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <StatusCard
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    Cross: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <RaifCross
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    Check: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <RaifCheck
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    InCart: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <RaifInCart
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    LetterVerified: function Icon ({opacity = '0.3', secondaryOpacity = '1', className, fill = '#fff', secondaryFill = '#fff'}) {
      return <RaifLetterVerified
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
        className={className}
      />;
    },
    LetterNoVerified: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <LetterNoVerified
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    SelectStepNominal: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <SelectStepNominal
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    Pencil: function Icon ({opacity = '0.3', secondaryOpacity = '1', fill = '#fff', secondaryFill = '#fff'}) {
      return <Pencil
        opacity={opacity}
        secondaryOpacity={secondaryOpacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
  }
};

Object.freeze(appIcons);

export const GetIcon = ({
  opacity = '0.31',
  secondaryOpacity = '1',
  fill = '#fff',
  secondaryFill = '#fff',
  width = '16',
  iconName,
  className = '',
}) => {
  const currentDomain = store.getState().settings.currentDomainName;
  if (appIcons[currentDomain] && appIcons[currentDomain][iconName]) {
    return appIcons[currentDomain][iconName]({opacity, className, secondaryOpacity, fill, secondaryFill, width});
  } else {
    return appIcons.default[iconName]({opacity, className, secondaryOpacity, fill, secondaryFill, width});
  }
};
