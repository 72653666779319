import React from 'react';
import {useSelector} from 'react-redux';

import {IStore} from 'store/store';
import {useMediaQuery} from 'utils/hooks';

import Popup from 'components/popup/Popup';
import Timer from 'components/timer/Timer';

import like from './assets/images/like.svg';

import './CompletedActivationCard.scss';

const CompletedActivationCard = () => {
  const isMobile = useMediaQuery(768);
  
  const completedActivationCard =
    useSelector((state: IStore) => state.popup.popup.completedActivationCard);
  const props = useSelector((state: IStore) => state.popup.props);

  const date = typeof props === 'string'
    ? `до ${props.split('-')[0]}`
    : 'бессрочный';

  return (
    <Popup 
      id={'completedActivationCard'} 
      mode={`${isMobile ? 'mobile_bottom-full' : 'classic'}`} 
      stylesContent={'completed-activation-card'}>
      <div className={'completed-activation-card__container'}>
        <img src={like} alt="like" className={'completed-activation-card__icon'}/>
        <h2 className={'completed-activation-card__title'}>Баланс вашего кошелька успешно пополнен</h2>
        <p className={'completed-activation-card__date'}>Срок действия баланса {date}</p>
        <Timer popupStatus={{completedActivationCard}} />
      </div>
    </Popup>
  );
};

export default CompletedActivationCard;
