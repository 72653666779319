import {Dispatch, SetStateAction} from 'react';

export const findLink = (text: string) => {
  const findProtocol =
    text.search(/(https|http|www)/ig);
  const findSpaceAfterLink =
    text.includes(' ', findProtocol) ? text.indexOf(' ', findProtocol) : text.length;
  const finalLink =
    text.slice(findProtocol, findSpaceAfterLink);
  const addProtocol =
    finalLink.search(/(https|http)/ig) === -1 ? `https://${finalLink}` : finalLink;

  return findProtocol !== -1 ? addProtocol : undefined;
};

export const currentYear = () => {
  return new Date().getFullYear();
};

export const lazyLoading = (
  lastItem: any,
  observerLoader: any,
  setScroll: Dispatch<SetStateAction<number>>,
  count: number
) => {
  const actionInSight = (entries: any) => {
    if (entries[0].isIntersecting) {
      setScroll(innerScroll => innerScroll + count);
    }
  };

  if (observerLoader.current) {
    observerLoader.current.disconnect();
  }

  observerLoader.current = new IntersectionObserver(actionInSight);
  if (lastItem.current) {
    observerLoader.current.observe(lastItem.current);
  }
};

export const getMarkupValue = (
  markup: string | number | { name: string; markup: string | number } | undefined): number => {
  if (markup === undefined) {
    return 0;
  }

  if (typeof markup === 'object') {
    return typeof markup.markup === 'number' ? markup.markup : parseInt(markup.markup);
  }

  if (typeof markup === 'string') {
    return parseInt(markup, 10);
  }

  return markup;
};

export function getScrollbarWidth() {
  if (typeof window === 'undefined') return 0;
  const scrollDiv = document.createElement('div');
  scrollDiv.style.visibility = 'hidden';
  scrollDiv.style.overflow = 'scroll';
  scrollDiv.style.width = '100px';
  scrollDiv.style.position = 'absolute';
  document.body.appendChild(scrollDiv);

  const innerDiv = document.createElement('div');
  innerDiv.style.width = '100%';
  scrollDiv.appendChild(innerDiv);
  // получаем разницу div со скроллом и без = размер скролла
  const scrollbarWidth = scrollDiv.offsetWidth - innerDiv.offsetWidth;
  document.body.removeChild(scrollDiv);
  
  return scrollbarWidth;
}

export function hasScrollbar(): boolean {
  if (typeof window === 'undefined') return false;
  
  return document.documentElement.scrollHeight > window.innerHeight;
}

export const updateBodyStyles = (scrollbarWidth?: number) => {
  const windowWidth = window.innerWidth;

  if (windowWidth >= 1024) {
    if (hasScrollbar()) {
      const scroll = scrollbarWidth ? `${scrollbarWidth}px` : '';
      
      document.body.style.marginRight = scroll;
    }
  }
};

export const closeModal = () => {
  const body = document.body;

  body.classList.remove('modal-open');
  body.removeAttribute('style');
  updateBodyStyles();
};

export const isMobileAndTouchDevice = () => {
  // для серверной части нужна такая проверка
  if (typeof window === 'undefined' || typeof navigator === 'undefined' || typeof document === 'undefined') {
    return false; // для сервера нужно возвращать что то конкретное
  }

  const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  const screenWidth = window.screen.width;
  // проверка User-Agent
  const userAgent = navigator.userAgent || navigator.vendor;
  const isIPad = /iPad|Macintosh/i.test(userAgent) && 'ontouchend' in document;
  const isMobileUserAgent = /android|iphone|ipad|ipod|windows phone/i.test(userAgent);
  
  return isTouchDevice && (isIPad || isMobileUserAgent) && screenWidth <= 1366;
};

export const isIOSDevice = () => {
  // Проверка для серверной части
  if (typeof window === 'undefined' || typeof navigator === 'undefined' || typeof document === 'undefined') {
    return false;
  }

  const userAgent = navigator.userAgent || navigator.vendor;
  // Проверка на iPhone, iPad и iPod
  const isIOS = /iPhone|iPad|iPod/i.test(userAgent);
  // Дополнительная проверка для iPad на macOS (они идентифицируются как Macintosh)
  const isIPadMac = /Macintosh/i.test(userAgent) && 'ontouchend' in document;

  return isIOS || isIPadMac;
};