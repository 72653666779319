import React from 'react';

import Popup from 'components/popup/Popup';
import {useMediaQuery} from 'utils/hooks';

import danger from './assets/danger.svg';

import './MoneyTransferAlert.scss';

const MoneyTransferAlert = () => {
  const isMobile = useMediaQuery(768);

  return (
    <Popup 
      id={'moneyTransferAlert'}
      mode={`${isMobile ? 'mobile_bottom-full' : 'classic'}`} 
      stylesContent={'money-transfer-alert-popup__content'}>
      <div className={'money-transfer-alert'}>
        <img src={danger} className={'money-transfer-alert__image'} alt={'image'}/>
        <div className={'money-transfer-alert__title'}>
          При пополнении баланса мобильного телефона установлены дополнительные лимиты на зачисление
          <br/>
          денежных средств в месяц.
        </div>
        <div>
          <ul className={'money-transfer-alert__list'}>
            <li>
              На один номер телефона:
            </li>
            <li>
              Теле2 – 1 000 руб/мес.
            </li>
            <li>
              Мегафону, Билайну и МТС – не более 5 000 руб/мес.
            </li>
          </ul>
          <div className={'money-transfer-alert__subtitle'}>
            При превышении лимитов, на стороне вашего оператора, может взиматься дополнительная комиссия.
          </div>
          <b className={'money-transfer-alert__subtitle'}>
            Будьте внимательнее!
          </b>
        </div>
      </div>
    </Popup>
  );
};

export default MoneyTransferAlert;
