import React, {useEffect, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from 'utils/hooks';

import env from 'env/env.json';

import {getNDFLAuthToken} from 'store/components/AuthSlice';
import NDFLPage from '../NDFLPage/NDFLPage';

import './NDFLWidget.scss';

const NdflWidget = () => {
  const dispatch = useAppDispatch();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  
  const ndflToken = useAppSelector(state => state.auth.user?.ndfl_auth_token);
  const [showWidget, setShowWidget] = useState(true);

  useEffect(() => {
    dispatch(getNDFLAuthToken());
  }, []);

  const sendMessageToIframe = (message: string) => {
    if (iframeRef.current) {
      const iframeWindow = iframeRef.current.contentWindow;
      iframeWindow?.postMessage({temporary_refresh_token: message}, '*');
    } else {
      console.error('Iframe not found.');
    }
  };

  useEffect(() => {
    if (ndflToken) {
      sendMessageToIframe(ndflToken);
    }
  }, [ndflToken]);

  useEffect(() => {
    const handleMessage = (event: any) => {
      if (event.data?.type === 'iframeHeight') {
        const height = event.data.height;
        const iframe = iframeRef.current;
        
        if (iframe) {
          iframe.style.height = `${height}px`;
        }
      }

      if (event.data?.type === 'updateStatus') {
        const widgetStatus = event.data.widgetMessage;
        
        if (widgetStatus) {
          dispatch(getNDFLAuthToken()).then(() => {
            setShowWidget(false);
          });
        }
      }
    };
  
    window.addEventListener('message', handleMessage);
  
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [iframeRef]);

  return (
    <>
      {ndflToken && showWidget ? (
        <iframe
          src={`${env.NDFL_URL}?temporary_token=${ndflToken}`}
          ref={iframeRef}
          className="ndfl-widget__iframe"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      ) : null}
      {!showWidget ? (
        <NDFLPage
          onClick={() => setShowWidget(false)}
        />
      ) : null}
    </>
  );
};

export default NdflWidget;
