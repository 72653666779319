import React from 'react';

import {GetIcon} from 'store/enums/normalizedIcons';

const VerifiedEmailMessage = ({
  icon,
  iconProps,
  text,
  className,
}: {
	icon: string;
	iconProps?: any;
	text: string;
  className?: string,
}) => (
  <>
    <span className={'completed-verified-email__icon'}>
      <GetIcon
        iconName={icon}
        className={`${className} completed-verified-email__icon-email`}
        {...iconProps}
      />
    </span>
    <h2 className={'completed-verified-email__title'}>{text}</h2>
  </>
);

export default VerifiedEmailMessage;
