import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';

import {IStore} from 'store/store';
import Title from 'components/title/Title';
import InnerTabs from 'components/innerTabs/InnerTabs';
import {GetIcon} from 'store/enums/normalizedIcons';
import PwaManual from './assets/components/PwaManual/PwaManual';
import PwaBanner from './assets/components/PwaBanner/PwaBanner';

import jivo from './assets/images/jivo.svg';
import telegram from './assets/images/telegram.svg';
import whatsapp from './assets/images/whatsapp.svg';

import helpBannerImage from './assets/images/help-banner.svg';

import './Help.scss';

interface IHelpItem {question: string, answer: string}
interface IHelpList {[key: string]: IHelpItem[]}
interface IHelpListProp {
  isFirstItem: boolean,
  active: boolean,
  index: number,
  setActive: Dispatch<SetStateAction<number |null>>
}

const HelpItem = (
  {
    question,
    active,
    setActive,
    index,
    answer,
    isFirstItem
  }: IHelpItem & IHelpListProp
) => {
  return (
    <div className={`help-item help-item_${active ? 'show' : 'hide'} help-item_${isFirstItem ? 'first-item' : ''}`}>
      <div
        className={`help-item__title help-item__title_${active ? 'active' : ''}`}
        onClick={() => setActive(active ? null : index)}>
        <span className={'help-item__title-text'}>
          {question}
        </span>
        <GetIcon iconName={'ChevronIcon'} />
      </div>
      <div
        className={`
          help-item__text 
          ${active ? 'help-item__text_active' : ''}
        `}
        dangerouslySetInnerHTML={{ __html: answer }}
      />
    </div>
  );
};

const HelpBanner = () => {
  const openJivo = () => {
    // @ts-ignore
    jivo_api.open();
    return false;
  };

  return (
    <div className={'help-banner'}>
      <img src={helpBannerImage} className={'help-banner__image'} alt={'image'}/>
      <div className={'help-banner__title'}>
        Если у Вас остались вопросы,
        <br/>
        обращайтесь:
      </div>
      <div className={'help__container'}>
        <div className={'help__chat-container'} onClick={openJivo}>
          <img src={jivo} alt="jivo" className={'help__img'} />
          <span className={'help__text'}>обращайтесь в Jivo-чат</span>
        </div>
        <a
          href={'https://t.me/presentcard_bot'}
          target={'_blank'}
          className={'help__chat-container'}
          rel="noreferrer">
          <img src={telegram} alt="telegram" className={'help__img'} />
          <span className={'help__text'}>в telegram чат: presentcard_bot</span>
        </a>
        <a
          href={'https://wa.me/79016345854'}
          target={'_blank'}
          className={'help__chat-container'}
          rel="noreferrer">
          <img src={whatsapp} alt="whatsapp" className={'help__img'} />
          <span className={'help__text'}>обращайтесь в Whatsapp</span>
        </a>
        {window.location.href.includes('pepsico') ? 
          (
            <a
              href={'tel:+7 (499) 455-99-35'}
              target={'_blank'}
              className={'help__chat-container'}
              rel="noreferrer">
              <span className={'help__label'}>Тел:</span>
              <span className={'help__text'}>+7 (499) 455-99-35</span>
            </a>
          ) : null
        }
      </div>
    </div>
  );
};

const QuestionsList = ({dataList} : {dataList: IHelpList | null}) => {
  const [showedIndex, setShowedIndex] = useState<number | null>(null);

  const href = window.location.href;
  const isRaiffeisen = href.includes('raiffeisen');

  return (
    <div>
      {
        Object.entries(dataList ? dataList : {})?.map((item, parentIndex) => {
          if (isRaiffeisen && item[0] === 'Вопросы по НДФЛ') {
            return;
          }

          return (
            <div key={parentIndex} className={'help__questions-group'}>
              <div className={'help__questions-group-title'}>
                {item[0]}
              </div>
              {
                item[1].map((questionItem, index) => {
                  return (
                    <HelpItem
                      key={questionItem.question}
                      isFirstItem={index === 0}
                      setActive={setShowedIndex}
                      index={parseInt(`${index}${parentIndex}`)}
                      active={showedIndex === parseInt(`${index}${parentIndex}`)}
                      question={questionItem.question}
                      answer={questionItem.answer}
                    />
                  );
                })
              }
            </div>
          );
        })
      }
    </div>
  );
};

const QuestionsListNDFL = ({dataList}:{dataList: IHelpItem[] | null}) => {
  const [showedIndex, setShowedIndex] = useState<number | null>(null);

  return (
    <div className={'help__ndfl-page'}>
      {
        dataList ? dataList.map((questionItem, index, mappingArr) => {
          return (
            <HelpItem
              key={questionItem.question}
              isFirstItem={index === 0}
              setActive={setShowedIndex}
              index={parseInt(`${index}`)}
              active={showedIndex === parseInt(`${index}`)}
              question={questionItem.question}
              answer={questionItem.answer}
            />
          );
        }) : null
      }
    </div>
  );
};

const Help = () => {
  const history = useHistory();
  const location = useLocation();
  
  const [active, setActive] = useState(0);
  const [data, setData] = useState<IHelpList | null>(null);
  const user = useSelector((state: IStore) => state.auth.user);

  const hashes: any = {
    '#ndfl': 1,
    '#application': 2,
  };

  useEffect(() => {
    fetch('/help.json')
      .then(response => response.json())
      .then(data => {
        setData(data);
      })
      .catch(error => console.error('Error fetching JSON:', error));
  }, []);

  const changeActiveTab = (index: number) => {
    const scrollPosition = window.scrollY;
    setActive(index);

    const hash = Object.keys(hashes).find(key => hashes[key] === index);
    
    if (hash) {
      history.push(`help${hash}`);
    } else {
      history.push('help');
    }
    
    requestAnimationFrame(() => {
      window.scrollTo(0, scrollPosition);
    });
  };

  const NDFLName = data ? Object.keys(data).find(item => item.includes('НДФЛ')) : '';
  const PwaName = data ? Object.keys(data).find(item => item.includes('приложение')) : '';

  const generalQuestions = data ? Object.fromEntries(Object.entries(data).filter(([key]) => key !== NDFLName && key !== PwaName)) : {};
  const pwaQuestions = data ? Object.fromEntries(Object.entries(data).filter(([key]) => key === PwaName)) : {};

  const tabBar = [
    {
      name: 'Общие вопросы',
      child: <QuestionsList dataList={generalQuestions} />,
      id: 'questions',
      hidden: false
    },
    {
      name: 'Вопросы по НДФЛ',
      child: <QuestionsListNDFL
        dataList={data ? data[NDFLName ? NDFLName : ''] : null}
      />,
      id: 'ndflQuestions',
      hidden: !user?.tax
    },
    {
      name: 'Мобильное приложение',
      child: <PwaManual pwaQuestions={pwaQuestions} />,
      id: 'questions',
      hidden: false
    },
  ];

  useEffect(() => {
    const activeIndex = hashes[location.hash] ?? 0;
    setActive(activeIndex);
  }, [location]);

  return (
    <div className={'help'}>
      <Title>Помощь</Title>
      <div className={'help__global-container'}>
        <InnerTabs tabs={tabBar} changeActiveTab={changeActiveTab} activeTab={active} enableDragScroll />
        <div>
          <HelpBanner />
          <PwaBanner />
        </div>
      </div>
    </div>
  );
};

export default Help;
