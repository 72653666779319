import React, {useEffect, useRef, useState} from 'react';

import {useAppDispatch, useAppSelector} from 'utils/hooks';
import {useDebouncedFunction} from 'hooks';

import {setActiveCategory, setLoading, setActiveAvailability, setChangeSearchData} from 'store/components/PrizesSlice';

import arrow from './assets/images/arrow.svg';

import './CategoriesAvailabilitySelector.scss';

interface ICategories {
  marginBottom?: number | string;
  selectorType: 'categories' | 'availability';
}

const CategoriesAvailabilitySelector: React.FC<ICategories> = ({marginBottom, selectorType}) => {
  const dispatch = useAppDispatch();
  const rootEl = useRef<any>(null);

  const cardsFilterLength = useAppSelector(state => state.prizes.cardsTotal);
  const processedList = selectorType === 'categories'
    ? useAppSelector(state => state.prizes.categories)
    : useAppSelector(state => state.prizes.availability);

  const [visibleDropdown, setVisibleDropdown] = useState(false);

  const categoriesInlineStyle = {
    marginBottom: marginBottom,
  };

  useEffect(() => {
    const onClick = (e: any) => {
      if (rootEl.current && !rootEl.current.contains(e.target)) {
        setVisibleDropdown(false);
      }
    };
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, []);

  const debouncedHandleLoading = useDebouncedFunction(() => {
    dispatch(setLoading({ name: 'prizes', value: true }));
    dispatch(setChangeSearchData(true));
    setTimeout(() => {
      dispatch(setChangeSearchData(false));
    }, 0);
    setTimeout(() => dispatch(setLoading({ name: 'prizes', value: false })), 500);
  }, 300);

  const selectItem = (index: number) => {
    if (selectorType === 'categories') {
      dispatch(setActiveCategory(index));
    }
    if (selectorType === 'availability') {
      dispatch(setActiveAvailability(index));
    }

    debouncedHandleLoading();
  };

  const searchActive= () => {
    const select: string[] = [];

    const changeLengthName = (name: string) => {
      return name.length > 10 ? `${name.slice(0, 10).replace(',', '')}...` : name;
    };

    processedList.filter(selectListItem =>
      selectListItem.active && select.push(changeLengthName(selectListItem.name))
    );

    return select.length > 2
      ? `${select.splice(0, 2).join(', ')}...`
      : select.join(', ');
  };

  const changeVisibleDropDown = () => {
    setVisibleDropdown(innerVisibleDropdown => !innerVisibleDropdown);
  };

  return (
    <div className={'categories'} style={categoriesInlineStyle} ref={rootEl}>
      <h3 className={'categories__title'}>{selectorType === 'categories'? 'Категории' : 'В наличии'}</h3>
      <div className={'categories__container'}>
        <div
          className={
            visibleDropdown
              ? 'categories__select categories__select_active'
              : 'categories__select'
          }
          onClick={changeVisibleDropDown}>
          <span
            className={
              visibleDropdown
                ? 'categories__select-categories categories__select-categories_active'
                : 'categories__select-categories'
            }>
            {searchActive() || 'Все категории'}
          </span>
          <img
            src={arrow}
            alt="arrow"
            className={
              visibleDropdown
                ? 'categories__arrow categories__arrow_rotate'
                : 'categories__arrow'
            }
          />
        </div>
        <div
          className={
            visibleDropdown
              ? 'categories__dropdown categories__dropdown_show'
              : 'categories__dropdown'
          }>
          {
            selectorType === 'categories'
              ? (
                <span className={'categories__name-categories categories__name-categories_bold'}>
                  Кол-во брендов: {cardsFilterLength}
                </span>
              ) : null
          }
          {
            processedList?.map((item, index) => (
              <span
                className={
                  item.active
                    ? 'categories__name-categories categories__name-categories_active'
                    : 'categories__name-categories'
                }
                key={index}
                onClick={() => selectItem(index)}>
                {item.name}
              </span>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default CategoriesAvailabilitySelector;
