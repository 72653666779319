import React, {useRef} from 'react';

import {useCenterActiveTab, useDragScroll} from 'hooks';

import './InnerTabs.scss';

interface IInnerTab {
  tabs: {
    child: any,
    name: string,
    hidden?: boolean,
    onClick?: () => void
  }[],
  id?: string,
  changeActiveTab: (prop: number) => void,
  activeTab: number;
  enableDragScroll?: boolean;
}

const InnerTabs = ({tabs, changeActiveTab, id, activeTab, enableDragScroll = false}: IInnerTab) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const tabRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});

  const isMobileWrapper: { [key: string]: string } = {
    profile: 'true',
    default: 'false'
  };

  useCenterActiveTab({containerRef, tabRefs, activeTab});
  useDragScroll(containerRef, {enabled: enableDragScroll});

  return (
    <div className={'inner-tabs'} data-mobile-wrapper={isMobileWrapper[id ?? 'default']}>
      <div className={'inner-tabs__container'} ref={containerRef}>
        {tabs.map((item, index) => {
          if (item.hidden) {
            return null;
          }

          const click = () => {
            changeActiveTab(index);
            item.onClick && item.onClick();
          };

          return (
            <div
              key={index}
              onClick={click}
              ref={(el) => (tabRefs.current[index] = el)}
              className={
                index === activeTab
                  ? 'inner-tabs__item inner-tabs__item_active'
                  : 'inner-tabs__item'
              }>
              {item.name}
            </div>
          );
        })}
      </div>
      <div className={'inner-tabs__content'}>
        {tabs[activeTab].child}
      </div>
    </div>
  );
};

export default InnerTabs;
