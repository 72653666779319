import React from 'react';

import {useAppSelector, useMediaQuery} from 'utils/hooks';
import env from 'env/env.json';

import Popup from 'components/popup/Popup';

import './TestografPopup.scss';

const TestografPopup = () => {
  const popup = useAppSelector(state => state.popup.popup);
  const user = useAppSelector(state => state.auth.user);

  const isMobile = useMediaQuery(768);

  const surveyURL = `${env.testograf}?uid=${user?.id ?? ''}`;

  return (
    <Popup 
      id={'testograf'}
      mode={`${isMobile ? 'mobile_bottom-full' : 'classic'}`} 
      stylesContent={'testograf-popup'}>
      {popup['testograf'] ? (
        <>
          <iframe
            src={surveyURL}
            className="testograf-popup__iframe"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </>
      ) : null}
    </Popup>
  );
};

export default TestografPopup;
