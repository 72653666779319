import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {IStore} from 'store/store';
import {hidePopup, showPopup} from 'store/components/PopupSlice';
import {useMediaQuery} from 'utils/hooks';

import Popup from 'components/popup/Popup';
import Button from 'components/button/Button';
import Timer from 'components/timer/Timer';

import warning from './assets/images/warning.svg';
import card from './assets/images/card.svg';

import './RejectActivatedCard.scss';

const RejectActivatedCard = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(768);

  const rejectActivatedCard =
    useSelector((state: IStore) => state.popup.popup.rejectActivatedCard);

  const openActivationCard = () => {
    dispatch(hidePopup('rejectActivatedCard'));
    dispatch(showPopup('activationCard'));
  };

  return (
    <Popup 
      id={'rejectActivatedCard'}
      mode={`${isMobile ? 'mobile_bottom-full' : 'classic'}`} 
      stylesContent={'reject-activated-card__popup-content'}>
      <div className={'reject-activated-card__container'}>
        <img src={warning} alt="warning" className={'reject-activated-card__icon'} />
        <h3 className={'reject-activated-card__title'}>
          Срок действия сертификата истек{' '}
          <span className={'reject-activated-card__title_color-pink'}>12.05.2022</span>
        </h3>
        <Button
          backgroundColor={'#4661C4'}
          borderRadius={8}
          marginBottom={16}
          onClick={openActivationCard}
          uppercase={false}
          width={244}
          icon={card}>
          Активировать новую карту
        </Button>
        <Timer popupStatus={{rejectActivatedCard}} />
      </div>
    </Popup>
  );
};

export default RejectActivatedCard;