import React, {useEffect, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {CSSTransition} from 'react-transition-group';

import {getSearchForOtherProject, useAppDispatch, useMediaQuery} from 'utils/hooks';
import {closeModal} from 'utils/functions';
import {useBodyClass, useDraggableModal} from 'hooks';

import {IStore} from 'store/store';
import {hidePopup} from 'store/components/PopupSlice';

import IconSprite from 'components/IconSprite/IconSprite';

import './Popup.scss';

interface IPopup {
  children: any;
  id: string;
  className?: string;
  invisibleHeader?: boolean;
  invisibleFooter?: boolean;
  mode?: string;
  timeout?: number;
  close?: boolean;
  draggable?: boolean;
  stylesContent?: string;
  stylesContainer?: string;
  onClose?: () => void;
}

const Popup: React.FC<IPopup> = ({
  children, 
  id, 
  className, 
  stylesContent,
  stylesContainer,
  onClose,
  close = true,
  mode = 'classic',
  invisibleHeader = true,
  invisibleFooter = true,
  timeout = 200,
  draggable = false,
}) => {
  const dispatch = useAppDispatch();
  const nodeRef = useRef(null);
  const isMobile = useMediaQuery(768);
  const {search} = useLocation();

  const visiblePopup = useSelector((state: IStore) => state.popup.popup);

  const [visibleClosePopupForOtherProject, setVisibleClosePopupForOtherProject] = useState<boolean>(false);
  
  const visible = visiblePopup[id];
  
  const handleWrapperStopPropagation = (event: any) => {
    event.stopPropagation();
  };

  const closePopup = () => {
    if (!visibleClosePopupForOtherProject) {
      dispatch(hidePopup(id));
      close && closeModal();
      onClose && onClose();
    }
  };

  const { 
    modalRef, 
    handleTouchStart, 
    handleTouchMove, 
    handleTouchEnd 
  } = useDraggableModal({
    onClose: () => {
      dispatch(hidePopup(id));
      closeModal();
      onClose && onClose();
    },
    visible,
    draggable
  });

  useBodyClass('modal-open', visiblePopup[id] && isMobile);

  useEffect(() => {
    getSearchForOtherProject(search, setVisibleClosePopupForOtherProject);
  }, []);

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={visiblePopup[id]}
      timeout={timeout}
      classNames={`modal__transitions-${mode}`}
      appear={visiblePopup[id]}
      unmountOnExit>
      <div 
        className={`popup__container ${stylesContainer}`} 
        data-visible-header={invisibleHeader}
        data-visible-footer={invisibleFooter}
        ref={nodeRef}>
        <div className={'popup__background'} />
        <div className={'popup__wrapper modal'} onClick={closePopup}>
          <div 
            ref={draggable ? modalRef : null} 
            className={`popup__content ${stylesContent}`} 
            onClick={handleWrapperStopPropagation} 
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}>
            <div className={'popup__invisible-header'} />
            {draggable && (
              <div className={'draggableHandle'}>
                <div className={'draggableRect'} />
              </div>
            )}
            {
              !visibleClosePopupForOtherProject
                ?  <IconSprite id={'close'} className={'popup__close'} onClick={closePopup} />
                : null
            }
            {children}
            <div className={'popup__invisible-footer'} />
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default Popup;