import React, {useEffect, useRef, useState} from 'react';
import _ from 'lodash';

import env from 'env/env.json';

import {useAppDispatch, useAppSelector} from 'utils/hooks';

import {handleCounter, INominal, INominalsToOrder} from 'store/components/PrizesSlice';
import {changeAmountCart, ICartList, onChangeAmountForInput} from 'store/components/CartSlice';

import minus from './assets/images/minus.svg';
import plus from './assets/images/plus.svg';

import './Counter.scss';

type GetObjDifferentKeys<T, U> = Omit<T, keyof U> & Omit<U, keyof T>

type GetObjSameKeys<T, U> = Omit<T | U, keyof GetObjDifferentKeys<T, U>>

type DeepMergeTwoTypes<T, U> =
// "не общие" (уникальные) ключи - опциональны
  Partial<GetObjDifferentKeys<T, U>>
  // общие ключи - обязательны
  & { [K in keyof GetObjSameKeys<T, U>]: T[K] | U[K] }

interface ICounter {
  item: any;
  className?: string;
  modeCounter: string;
  error?: any;
  setError?: any;
  setSelected?: any
}

const Counter: React.FC<ICounter> = ({item, className, modeCounter, error, setSelected}) => {
  const dispatch = useAppDispatch();

  const currentItem = modeCounter === 'orderCard' ? item[1] : item;

  const card_data = useAppSelector(state => state.prizes.card);
  const total = useAppSelector(state => state.cart.total);
  const total_ndfl = useAppSelector(state => state.cart.total_tax);
  const purse = useAppSelector(state => state.dashboard.purse);
  const loading = useAppSelector(state => state.prizes.loading);

  const [counter, setCounter] = useState<string | number | undefined>(modeCounter === 'orderCard' ? currentItem.count : currentItem.amount);

  const sumTotal = Object.values(card_data?.nominals).reduce((
    previousValue: number,
    currentValue: INominal
  ) => {
    const value = previousValue + (+currentValue.nominal * +currentValue.count);
    const NDFL = purse?.tax_payer === 'participant' && purse.is_taxable_eps
      ? (Math.round((+currentItem.nominal / env.ndfl_value) - (+currentItem.nominal))) * +currentValue.count
      : 0;
    return value + NDFL; //TODO Исправить NDFLValue и NDFL
  }, 0) || 0;

  const NDFLValue = purse?.tax_payer === 'participant' && purse.is_taxable_eps
    ? (Math.round((+currentItem.nominal / env.ndfl_value) - (+currentItem.nominal))) * +currentItem.count
    : 0;

  const sumNominal = modeCounter === 'cart' ? +currentItem.nominal : +currentItem.nominal + sumTotal;
  const disabledPlusCounter = +total + sumNominal + NDFLValue > (purse?.balance || 0);

  const handleValue = (mode: string) => {
    if (modeCounter === 'orderCard') {
      dispatch(handleCounter({id: item[0], mode}));
    } else if (modeCounter === 'cart') {
      dispatch(changeAmountCart({id: parseInt(currentItem.id), mode, error}));
    }
  };

  const updateAmount = (text: string | number | undefined) => {
    const minValue = text || 0;

    dispatch(onChangeAmountForInput({id: parseInt(currentItem.nominal), amount: +minValue, error}));
  };

  const debounce = useRef(_.debounce(updateAmount, 1000));

  const onChangeCounter = (text: string | number | undefined) => {
    if (text && text.toString().length < 3 || !text) {
      setCounter(text);
      text && debounce.current(text);
    }
  };

  const plusCounter = () => {
    if (modeCounter === 'orderCard') {
      return !disabledPlusCounter ? handleValue('plus') : error();
    }
    return !disabledPlusCounter && !loading['cartLoading'] ? handleValue('plus') : error();
  };

  useEffect(() => {
    if (modeCounter === 'orderCard') {
      setCounter(currentItem.count);
    } else if (modeCounter === 'cart') {
      // setCounter(item[1].amount);
    }
  }, [item]);

  useEffect(() => {
    setSelected && setSelected(!!counter);
  }, [counter]);

  return (
    <div className={['counter', className].join(' ')}>
      <img
        src={minus}
        alt="minus"
        className={'counter__symbols'}
        onClick={() => handleValue('minus')}
      />
      {modeCounter === 'orderCard' ? (
        <div className={'counter__value'}>
          {counter}
        </div>
      ) : (
        <input
          type="number"
          className={'counter__input'}
          value={counter}
          onChange={e => onChangeCounter(e.target.value)}
        />
      )}
      <img
        src={plus}
        alt="plus"
        className={
          !disabledPlusCounter
            ? 'counter__symbols'
            : 'counter__symbols counter__symbols_opacity_05'
        }
        onClick={plusCounter}
      />
    </div>
  );
};

export default Counter;
