export const updateFavicon = () => {
  const faviconPaths = [
    'asg',
    'brucite',
    'geo',
    'korovka',
    'makfa',
    'megafon',
    'mtsfirst',
    'mission',
    'nloto',
    'proektirovanie',
    'raiff',
    'smeg',
    'sargazarm',
    'tele2',
    'men-day',
    'aclub',
    'womens-day',
    'r-express',
    'mascoglass',
    'rubin',
    '366',
    'gdnprof',
    'lider-abc',
    'mts',
    'pepsico',
    'rtmis',
    'stg',
  ];
  
  const href = window.location.href;
  const finded = faviconPaths.find((item) => href.includes(item)) || '';
  const domainName = finded ? `-${finded}` : '';
  const path = finded ? 'favicon/other-favicon/favicon' : 'favicon/favicon';
  const manifestPath = finded ? `/manifest/manifest-${finded}.json` : '/manifest.json';

  const oldLinks = document.querySelectorAll('link[rel="icon"], link[rel="apple-touch-icon"], link[rel="manifest"]');

  oldLinks.forEach((link) => link.remove());

  const links = [
    { href: `${path}${domainName}.ico`, type: 'image/x-icon', rel: 'icon' },
    { href: `${path}${domainName}16x16.png`, type: 'image/png', sizes: '16x16', rel: 'icon' },
    { href: `${path}${domainName}32x32.png`, type: 'image/png', sizes: '32x32', rel: 'icon' },
    { href: `${path}${domainName}180x180.png`, type: 'image/png', rel: 'apple-touch-icon' },
    { href: `${path}${domainName}192x192.png`, type: 'image/png', sizes: '192x192', rel: 'icon' },
    { href: `${manifestPath}`, rel: 'manifest' },
  ];

  links.forEach(({ href, type, sizes, rel = 'icon' }) => {
    const link = document.createElement('link');
    link.rel = rel;
    link.href = href;
    if (type) link.type = type;
    if (sizes) link.setAttribute('sizes', sizes);
    document.head.prepend(link);
  });
};

document.addEventListener('DOMContentLoaded', updateFavicon);
window.addEventListener('focus', updateFavicon);