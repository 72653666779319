import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {GetIcon} from 'store/enums/normalizedIcons';
import {IStore} from 'store/store';
import {StorageItemsNames} from 'enums';
import {useAppSelector} from 'utils/hooks';
import {sendEmailVerified} from 'store/components/ProfileSlice';

import Button from '../button/Button';

import './VerifiedEmail.scss';

const VerifiedEmail = () => {
  const pastTense = localStorage.getItem(StorageItemsNames.PAST_TENSE);
  const current = new Date().getTime() / 1000;
  const diff = Math.floor(current - parseInt(pastTense ? pastTense : ''));

  const dispatch = useDispatch();

  const user = useSelector((state: IStore) => state.auth.user);
  const is_required_email = useSelector((state: IStore) => state.auth.is_required_email);
  const colors = useAppSelector((state) => state.settings.colors);
  const error = useAppSelector((state) => state.profile.email_verified_res);
  const error_message = useAppSelector((state) => state.profile.error_message);
  const domain = useAppSelector((state) => state.settings.currentDomainName);
  const verifiedColor = colors[domain].letter.verified;
  const noVerifiedColor = colors[domain].letter.noVerified;

  const [visibleTip, setVisibleTip] = useState(false);
  const [visibleTipErr, setVisibleTipErr] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [time, setTime] = useState(120 - diff);

  const handleVisibleTip = () => {
    if (disabled) {
      return;
    }

    if (is_required_email || user?.email) {
      setDisabled(true);
      setTime(120);
      localStorage.setItem(StorageItemsNames.PAST_TENSE, `${new Date().getTime() / 1000}`);
      setTime(120);
      dispatch(sendEmailVerified());
    }
  };

  useEffect(() => {
    if (disabled) {
      setTimeout(() => {
        setDisabled(false);
      }, 120 * 1000);
    }
  }, [disabled]);

  useEffect(() => {
    if (error === 'error') {
      setVisibleTip(false);
      setVisibleTipErr(true);

      setTimeout(() => {
        setVisibleTipErr(false);
      }, 3000);
      setDisabled(true);
    } else if (error === 'ok') {
      setVisibleTipErr(false);
      setVisibleTip(true);

      setTimeout(() => {
        setVisibleTip(false);
      }, 3000);
    }
  }, [error]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (time > 0) {
        setDisabled(true);
        setTime((prevTimer) => prevTimer - 1);
      } else {
        setDisabled(false);
      }
    }, 1000);

    return () => {
      clearTimeout(timerId);
    };
  }, [time]);

  const emailNotVerified = () => {
    return disabled && time > 0 ? (
      <Button
        className={'verified-email__button verified-email__button_no-active'}>
        Повторный запрос через: {time > 0 ? timer() : null}
      </Button>
    ) : (
      <Button
        className={'verified-email__button verified-email__button_active'}
        icon={<GetIcon iconName={'LetterNoVerified'} fill={disabled ? '#F45384' : noVerifiedColor}/>}
        onClick={handleVisibleTip}>
        Подтвердить E-mail
      </Button>
    );

  };

  const emailVerified = () => (
    <div className={'verified-email__container'}>
      <span className={'verified-email__icon'} >
        <GetIcon iconName={'LetterVerified'} fill={verifiedColor} />
      </span>
      <span className={'verified-email__text verified-email__text_verified'}>
        E-mail подтвержден
      </span>
    </div>
  );

  const timer = () => {
    const min = Math.floor(time / 60);
    const sec = time - (min * 60);
    return (
      <div className={'verified-email__timer'}>
        {min > 9 ? min : `0${min}`}:{sec > 9 ? sec : `0${sec}`}
      </div>
    );
  };

  if (!user) {
    return null;
  }

  return (
    is_required_email || user.email ? (<div className={'verified-email'}>
      {user && user.status ? emailVerified() : emailNotVerified()}
      <div className={visibleTip ? 'verified-email__tip verified-email__tip_active' : 'verified-email__tip'}>
        <p className={'verified-email__info-text'}>
          <span className={'verified-email__info-text_first-child'}>
            На указанный почтовый ящик отправлено письмо, содержащее ссылку подтверждения адреса.
          </span>
          <span>Просто перейдите по ссылке из письма.</span>
        </p>
      </div>
      <div className={visibleTipErr ? 'verified-email__tip verified-email__tip_active' : 'verified-email__tip'}>
        <p className={'verified-email__info-text'}>
          {error_message ? (
            <span>{error_message}</span>
          ) : (
            <>
              <span className={'verified-email__info-text_first-child'}>
                Произошел технический сбой
              </span>
              <span>Повторите попытку позже</span>
            </>
          )}
        </p>
      </div>
    </div>) : null
  );
};

export default VerifiedEmail;
