import React from 'react';

import Popup from 'components/popup/Popup';
import {useAppSelector, useMediaQuery} from 'utils/hooks';

import './OrderStatusPopup.scss';

const OrderStatusPopup = () => {
  const isMobile = useMediaQuery(768);
  
  const popupProps = useAppSelector(state => state.popup.props);

  const openJivo = () => {
    // @ts-ignore
    jivo_api.open();
    return false;
  };

  const readyStatus = 2; //Полностью отгружен
  const rejectStatus = 3; //Отказ
  const outOfStockStatus = 4; //Нет в наличии
  const waitingToSentStatus = 6; //Ожидает отправки
  const changedStatus = 5; //Изменен

  const ReadyContent = () => (
    <>
      {popupProps.type === 'payments' ? (
        <>
          <h1 className={'ready-order-popup__title'}>Ваш платеж успешно выполнен</h1>
        </>
      ) : (
        <>
          <h1 className={'ready-order-popup__title'}>Сертификат отправлен Вам на почту.</h1>
          <p className={'ready-order-popup__description'}>
              Если в Личном кабинете почта не указана, обратитесь в&nbsp;
            <span onClick={openJivo} className={'ready-order-popup__link'}>
              Поддержку
            </span>
          </p>
        </>

      )}
    </>
  );

  const RejectContent = () => (
    <>
      {popupProps.type === 'payments'
        ? (
          <>
            <h1 className={'ready-order-popup__title'}>Ваш платеж отменен.</h1>
            <p className={'ready-order-popup__description'}>
              Баллы возвращены на баланс кошелька
            </p>
          </>
        )
        : (
          <>
            <h1 className={'ready-order-popup__title'}>Ваш заказ отменен.</h1>
            <p className={'ready-order-popup__description'}>
              Баллы возвращены на баланс кошелька
            </p>
          </>
        )}
    </>
  );

  const OutOfStockContent = () => {
    return (
      <>
        {popupProps.type === 'payments'
          ? (
            <>
              <h1 className={'ready-order-popup__title'}>Ваш платеж отменен.</h1>
              <p className={'ready-order-popup__description'}>
                Баллы возвращены на баланс кошелька
              </p>
            </>
          )
          : (
            <>
              <h1 className={'ready-order-popup__title'}>Заказ изменен</h1>
              <p className={'ready-order-popup__description'}>
                Новые серитфикаты добавлены в заказ
              </p>
            </>
          )}
      </>
    );
  };

  const WaitingToSent = () => (
    <>
      <h1 className={'ready-order-popup__title'}>Заказ создан</h1>
      <p className={'ready-order-popup__description'}>
        Ваш заказ в очереди на отправку
      </p>
    </>
  );

  const ChangedContent = () => (
    <>
      <h1 className={'ready-order-popup__title'}>Состав заказа изменен.</h1>
      <p className={'ready-order-popup__description'}>
        Сертификаты отправлены на E-mail получателя
      </p>
    </>
  );

  return (
    <Popup 
      id={'orderStatusPopup'}
      mode={`${isMobile ? 'mobile_bottom-full' : 'classic'}`} 
      stylesContent={'ready-order-popup'}>
      {popupProps.status === readyStatus && <ReadyContent/>}
      {popupProps.status === rejectStatus && <RejectContent/>}
      {popupProps.status === changedStatus && <ChangedContent/>}
      {popupProps.status === outOfStockStatus && <OutOfStockContent/>}
      {popupProps.status === waitingToSentStatus && <WaitingToSent/>}
    </Popup>
  );
};

export default OrderStatusPopup;
