import React from 'react';

import './CommissionItem.scss';

type CardType = {
  [key: string]: {
    name: string;
    markup?: string | { name: string; markup: string | number };
  };
};

type CommissionItemProps = {
  item: [string, { name?: string | null; markup?: string | number } | string];
  index: number;
  cards: CardType;
};

const CommissionItem: React.FC<CommissionItemProps> = ({ item, index, cards }) => {
  const [itemId, itemData] = item;
  const card = cards[itemId];
  
  const name =
		typeof itemData === 'object' &&
		itemData !== null &&
		itemData !== undefined &&
		itemData.name ? itemData.name : card?.name;
  const markup = typeof itemData === 'object' ? itemData.markup ?? card?.markup : card?.markup;

  if (!name || markup === undefined) return null;

  const markupName = typeof markup === 'object' && markup !== null ? markup.name : undefined;
  const markupValue = typeof markup === 'object' ? markup.markup : markup;

  return (
    <div key={index} className="commission-item__content">
      <span className="commission-item__name">
        {markupName || name}
      </span>
      <span className="commission-item__markup">
        +{Number(markupValue)}%
      </span>
    </div>
  );
};

export default CommissionItem;