import React, {useEffect, useState} from 'react';

import env from 'env/env.json';

import {useAppDispatch, useAppSelector} from 'utils/hooks';
import {suffix} from 'utils/suffix';

import {IPurse} from 'store/components/AuthSlice';
import {addNominalsInCard, ICardData, setVisibleForm} from 'store/components/PrizesSlice';
import {showPopup} from 'store/components/PopupSlice';
import {stringFormatter} from 'utils/stringFormatter';
import {getMarkupValue} from 'utils/functions';

import Button from 'components/button/Button';
import SecondInput from 'components/secondInput/SecondInput';

import {PlusIcon} from 'assets/images/IconPack';

import './SelectorStepNominals.scss';


const SelectorStepNominals = ({disabled, purse, total, onClick}: {disabled: boolean, onClick: (T: any) => void, purse: IPurse | null, total: number}) => {
  const dispatch = useAppDispatch();
  const {orderCard} = useAppSelector(state => state.popup.popup);
  const card_data: ICardData = useAppSelector(state => state.prizes.card);
  const status = useAppSelector(state => state.auth.user?.status);
  const isPepsico = window.location.href.includes('pepsi');
  const balance = purse?.balance ? purse.balance : 0;

  const [nominal, setNominal] = useState<string>('');
  const [qty, setQty] = useState('');
  const [moreOrLessNominals, setMoreOrLessNominals] = useState(`не больше ${card_data.max_nominal}`);
  const [totalForAddMyNominals, setTotalForAddMyNominals] = useState<any>(0);
  const [error, setError] = useState(true);
  const [errorClickValue, setErrorClickValue] = useState('');
  const [hide, setHide] = useState(true);

  const Markup = card_data.markup ? getMarkupValue(card_data.markup) : 0;
  const step = card_data.step;
  const possibility = step * (Markup / 100) + step <= balance;

  useEffect(() => {
    setHide(true);
  }, [onClick]);

  const change = (value: string, type: 'nominal' | 'qty', placeholder: string) => {
    if ((type === 'qty' && value < qty) || (type === 'nominal' && value < nominal)) {
      value = value.substring(0, value.length - (placeholder.length + 1));
    }

    const setValue = type === 'nominal' ? setNominal : setQty;

    const cleanedValue = value.replace(/\D/g, '');
    const formattedValue = `${cleanedValue} ${type === 'nominal' ? placeholder : placeholder}`;

    if (parseInt(cleanedValue) <= 0) {
      setValue('');
      return;
    }

    if (value.length) {
      setValue(formattedValue);
    } else {
      setValue('');
    }
  };

  const addNominals = () => {
    if (
      parseInt(nominal) % card_data.step === 0
      && parseInt(nominal) <= +card_data.max_nominal
      && parseInt(nominal) >= +card_data.min_nominal
      && parseInt(qty) !== 0
      && !disabled
      // && balance && (purse.balance - (nominal * (parseInt(Markup) / 100) + nominal))>= 0
    ) {
      const intNominal = parseInt(nominal);
      const intQty = parseInt(qty);

      setError(false);

      dispatch(addNominalsInCard({nominal: intNominal, qty: intQty}));
      setQty('');
      setNominal('');
      setHide(true);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    setNominal('');
    setQty('');
    dispatch(setVisibleForm(false));
    setError(false);
  }, [orderCard]);

  useEffect(() => {
    if (
      parseInt(nominal) % card_data.step === 0
      && parseInt(nominal) <= +card_data.max_nominal
      && parseInt(nominal) >= +card_data.min_nominal
      && parseInt(qty) !== 0
      && balance >= (parseInt(nominal) + parseInt(nominal) * (Markup / 100)) * parseInt(qty) + total
    ) {
      setError(false);
    } else {
      setError(true);
    }

    setMoreOrLessNominals(
      parseInt(nominal) < +card_data.min_nominal
        ? `не меньше ${card_data.min_nominal} ₽`
        : `не больше ${card_data.max_nominal} ₽`
    );

    if (nominal && qty) {
      setTotalForAddMyNominals(
        ((parseInt(nominal) + Math.floor(parseInt(nominal) * (Markup / 100))) * parseInt(qty)) || 0
      );
    }
  }, [nominal, qty]);

  const intervalError = () => setTimeout(() => {setErrorClickValue('');}, 2000);

  useEffect(() => {
    if (errorClickValue.length > 0) {
      intervalError();
    }
  }, [errorClickValue]);

  useEffect(() => {
    setMoreOrLessNominals(`не меньше ${card_data.min_nominal} ₽`);
  }, [card_data]);

  const byWithAll = () => {
    if (!balance) {
      return 0;
    }

    const max = card_data.max_nominal;
    const commissionValue = Markup ? Markup : 0;
    const NDFL_Value = purse?.is_taxable_eps && purse?.tax_payer === 'participant' ? env.ndfl_value : 0;

    const newStep = step + (step * (commissionValue / 100)) + (NDFL_Value ? step / NDFL_Value - step : 0);

    const nominal = Math.floor(balance / newStep) * step;

    const count = Math.floor(nominal / max);

    const nominals = [];

    if (count >= 1) {
      nominals.push({count, nominal: max});
      nominals.push({count: 1, nominal: nominal - (count * max)});
    } else {
      nominals.push({count: 1, nominal: nominal});
    }

    const markup = nominal * (commissionValue / 100);
    const ndfl = NDFL_Value ? nominal / NDFL_Value - nominal : 0;

    if (status) {
      dispatch(showPopup({
        popup: 'acceptByWithAllOrder',
        props: {
          nominals: nominals,
          markup: markup,
          ndfl: ndfl,
          name: card_data.name,
          sum: nominal + Math.floor(markup) + Math.round(ndfl),
          nominal: nominal,
          guid: card_data.guid_1c,
          ValidityPeriodNominal: card_data.validity_period_nominal,
        }
      }));
    } else {
      dispatch(showPopup('insufficientData'));
    }
  };

  const clickCatcher = (e: any) => {
    clearInterval(intervalError());
    if (isNaN(parseInt(qty)) || isNaN(parseInt(nominal))) {
      setErrorClickValue('Введите номинал и колличество номиналов');
      return;
    }
    if (parseInt(nominal) % card_data.step != 0) {
      setErrorClickValue('Введите номинал кратный шагу');
      return;
    }
    if (parseInt(nominal) > +card_data.max_nominal) {
      setErrorClickValue('Введенный номинал больше максимального значения');
      return;
    }
    if (parseInt(nominal) < +card_data.min_nominal) {
      setErrorClickValue('Введенный номинал меньше минимального значения');
      return;
    }
    if (balance < (parseInt(nominal) + parseInt(nominal) * (Markup / 100)) * parseInt(qty) + total) {
      setErrorClickValue('На вашем балансе недостаточно средств');
      return;
    }
    e.stopPropagation();
  };

  if (+card_data.step === 0) {
    return null;
  }

  return (
    <>
      {
        card_data.name && card_data.name.toLowerCase() === 'ozon' && possibility && !isPepsico ?
          (
            <Button className={'order-card__by-with-all'} onClick={byWithAll}>
              Купить на все&nbsp;
              <span className={'order-card__by-with-all order-card__by-with-all_bold'}>
                {stringFormatter(`${balance}`)} ₽
              </span>
            </Button>
          )
          : null
      }
      <div onClick={() => setHide(false)} className={`
        select-step-nominals-2
        ${hide ? '' : 'select-step-nominals-2__active'}
      `}>
        <div className={`
          select-step-nominals-2__title-container 
          ${hide ? 'select-step-nominals-2__show' : 'select-step-nominals-2__hide'}
        `}>
          <div className={'select-step-nominals-2__title'}>
            Добавить карту со своим номиналом
          </div>
          <div className={'select-step-nominals-2__subtitle'}>
            Под заказ {card_data.validity_period_nominal}
          </div>
        </div>
        <div className={`
          select-step-nominals-2__form-container
          ${hide ? 'select-step-nominals-2__hide' : 'select-step-nominals-2__show'}
        `}>
          <div className={'select-step-nominals-2__form-title'}>
            Добавить сертификат со своим номиналом
          </div>
          <div className={'select-step-nominals-2__input-container'}>
            <SecondInput
              value={nominal}
              setValue={(value: string) => change(value, 'nominal', ' ₽')}
              className={'select-step-nominals-2__nominal'}
              placeholder={'Номинал'}
              marginRight={8}
              type={'text'}
              isMask={false}
            />
            <SecondInput
              value={qty}
              setValue={(value: string) => change(value, 'qty', ' шт')}
              className={'select-step-nominals-2__qty'}
              placeholder={'Кол-во'}
              marginRight={12}
              type={'text'}
              isMask={false}
            />
          </div>
          <span
            className={`
              select-step-nominals-2__step 
              ${error ? 'select-step-nominals-2__step' : ''}
            `}>
            шаг номинала кратный {card_data.step} ₽ и {moreOrLessNominals}
          </span>
          <span onClick={clickCatcher}>
            <Button
              iconFloat={'right'}
              icon={error && nominal && qty ? null : <PlusIcon className={'select-step-nominals-2__icon-add'} />}
              disabled={error}
              onClick={addNominals}>
              {/*Добавить сертификат*/}
              {
                error && nominal && qty
                  ? `Общая cумма ${totalForAddMyNominals} ₽`
                  : `Добавить сертификат${suffix({value: 'certificateStepNominal', qty: parseInt(qty)})}`
              }
            </Button>
          </span>
        </div>
      </div>
      <div className={'select-step-nominals-2__error'}>
        {errorClickValue}
      </div>
    </>
  );
};

export default SelectorStepNominals;
