import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {IStore} from 'store/store';
import {getPwaState, setPwaState} from 'utils/pwaStorage';

import Popup from 'components/popup/Popup';
import IconSprite from 'components/IconSprite/IconSprite';
import Button from 'components/button/Button';

import './PwaPopup.scss';
import { setIsAutoTriggered } from 'store/components/PwaSlice';
import { closeModal, isIOSDevice } from 'utils/functions';
import { hidePopup } from 'store/components/PopupSlice';
import { useHistory } from 'react-router-dom';
import Timer from 'components/timer/Timer';

const PwaPopup = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const deferredPrompt = useSelector((state: IStore) => state.pwa.deferredPrompt);
  const isAutoTriggered = useSelector((state: IStore) => state.pwa.isAutoTriggered);
  const pwa =
      useSelector((state: IStore) => state.popup.popup.pwa);

  const [isInstalled, setIsInstalled] = useState(false);

  const isIOS = isIOSDevice(); // Определяем, iOS ли устройство
  const buttonText = isIOS ? 'Инструкция по установке' : 'Скачать приложение';

  const handleAutoClose = () => {
    const currentState = getPwaState();
    setPwaState({
      popupClosedAt: Date.now(),
      autoCloseCount: currentState.autoCloseCount + 1,
    });
  };

  const handleClose = () => {
    if (isAutoTriggered) {
      handleAutoClose();
    }
    dispatch(setIsAutoTriggered(false));
    closeModal();
    dispatch(hidePopup('pwa'));
    setIsInstalled(false);
  };

  const handleClick = () => {
    if (isIOS) {
      closeModal();
      dispatch(hidePopup('pwa'));
      history.push('help#application');
    }

    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          setPwaState({ isPwaInstalled: true, popupClosedAt: null, autoCloseCount: 0 });
          dispatch(setIsAutoTriggered(false));
          setIsInstalled(true);
        } else {
          if (isAutoTriggered) {
            handleAutoClose();
          }
          dispatch(setIsAutoTriggered(false));
        }
      }).catch((error) => {
        if (isAutoTriggered) {
          handleAutoClose();
        }
        dispatch(setIsAutoTriggered(false));
        console.log('Ошибка при обработке userChoice:', error);
      });
    } else {
      console.log('Событие deferredPrompt недоступно');
    }
  };

  return (
    <Popup 
      id={'pwa'} 
      mode={'mobile_bottom'}
      invisibleHeader={false}
      stylesContainer={'pwa__popup-container'}
      stylesContent={'pwa__popup-content pwa__popup-content_installed'}
      onClose={handleClose}>
      <div className={'pwa__container'}>
        {!isInstalled ? (
          <>
            <IconSprite id={'pwa-notification'} className={'pwa__notification-icon'} />
            <div className={'pwa__content'}>
              <h1 className={'pwa__title'}>
                Наш сайт доступен как мобильное приложение
              </h1>
              <p className={'pwa__text'}>
                Скачайте приложение для легкого и быстрого доступа к сайту
              </p>
              <Button
                className={'pwa__button'}
                onClick={handleClick}>
                {buttonText}
              </Button>
            </div>
          </>
        ) : (
          <div className={'pwa__wrapper_installed'}>
            <IconSprite id={'pwa-notification'} className={'pwa__notification-icon_installed'} />
            <div className={'pwa__content pwa__content_installed'}>
              <h1 className={'pwa__title pwa__title_installed'}>
                Приложение добавленно на&nbsp;главный экран
              </h1>
              <Timer popupStatus={{pwa}} />
            </div>
          </div>
        )}
      </div>
    </Popup>
  );
};

export default PwaPopup;
