import { useState, useRef, useCallback } from 'react';

const useClickLock = (delay = 1000) => {
  const [isLocked, setIsLocked] = useState(false);
  const lockRef = useRef(false);

  const lock = useCallback(() => {
    if (lockRef.current) return false;

    lockRef.current = true;
    setIsLocked(true);

    setTimeout(() => {
      lockRef.current = false;
      setIsLocked(false);
    }, delay);

    return true;
  }, [delay]);

  return { isLocked, lock };
};

export default useClickLock;