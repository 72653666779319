import React from 'react';

import IconSprite from 'components/IconSprite/IconSprite';

import './PwaBanner.scss';

const PwaBanner = () => {
  return (
    <div className={'pwa-banner__container'}>
      <IconSprite id={'pwa_icon_x64'} className={'pwa-banner__icon'} />
      <div className={'pwa-banner__content'}>
        <h3 className={'pwa-banner__label'}>Наш сайт доступен как мобильное приложение</h3>
        <p className={'pwa-banner__text'}>Инструкция по установке PWA-приложения поможет использовать сервис на вашем устройстве и не займет много места.</p>
      </div>
    </div>
  );
};

export default PwaBanner;
