import {useEffect, useRef} from 'react';

const useBodyClass = (className, condition, delay = 100) => {
  const isClassAddedByThisInstance = useRef(false);
  const activeModalsCount = useRef(0);
  const addClassTimerRef = useRef(null);
  const removeClassTimerRef = useRef(null);
  const hasParentModal = useRef(false);
  
  useEffect(() => {
    hasParentModal.current = document.body.classList.contains(className);

    if (condition) {
      clearTimeout(addClassTimerRef.current);
      clearTimeout(removeClassTimerRef.current);

      if (!isClassAddedByThisInstance.current) {
        addClassTimerRef.current = setTimeout(() => {
          activeModalsCount.current++;
          if (!hasParentModal.current) {
            document.body.classList.add(className);
          }
          isClassAddedByThisInstance.current = true;
        }, delay);
      }
    } else {
      clearTimeout(addClassTimerRef.current);
      
      if (isClassAddedByThisInstance.current) {
        activeModalsCount.current--;
        // Удаляем класс только если это не вложенная модалка
        if (activeModalsCount.current === 0 && !hasParentModal.current) {
          removeClassTimerRef.current = setTimeout(() => {
            document.body.classList.remove(className);
          }, delay);
        }
        isClassAddedByThisInstance.current = false;
      }
    }

    return () => {
      clearTimeout(addClassTimerRef.current);
      clearTimeout(removeClassTimerRef.current);
      
      if (isClassAddedByThisInstance.current) {
        activeModalsCount.current--;
        // Удаляем класс только если это не вложенная модалка и нет активных модалок
        if (activeModalsCount.current === 0 && !hasParentModal.current) {
          document.body.classList.remove(className);
        }
        isClassAddedByThisInstance.current = false;
      }
    };
  }, [className, condition, delay]);
};

export default useBodyClass;