import React, {useState} from 'react';
import {CSSTransition, SwitchTransition} from 'react-transition-group';
import ReactLoading from 'react-loading';

import './Button.scss';

interface IButton {
  children: any;
  onClick?: any;
  color?: string;
  loading?: boolean;
  icon?: any;
  width?: number | string;
  backgroundColor?: string;
  disabled?: boolean;
  marginBottom?: number;
  marginRight?: number;
  borderRadius?: number;
  uppercase?: boolean;
  className?: any;
  iconFloat?: string;
  fontSize?: string;
  height?: string;
  loaderColor?: string;
}

const Button: React.FC<IButton> = ({uppercase = true, iconFloat = 'left', loaderColor = '#fff', ...props}) => {
  const { 
    width, height, backgroundColor, disabled, marginBottom, 
    marginRight, borderRadius, color, fontSize, className, loading, ...restProps 
  } = props;
  
  const buttonContainerInlineStyle = {
    width,
    height,
    background: backgroundColor,
    opacity: disabled ? 0.6 : 1,
    marginBottom,
    marginRight,
    borderRadius,
  };

  const buttonInlineStyle = {
    color,
    fontSize,
  };

  const [animationClick, setAnimationClick] = useState<boolean>(false);

  const handleMouseDown = () => {
    setAnimationClick(true);
    setTimeout(() => setAnimationClick(false), 300);
  };

  const onClick = () => {
    if (!props.disabled) {
      props.onClick && props.onClick();
    }
  };

  const displayIcon = () => {
    if (!props.icon) return null;

    if (React.isValidElement(props.icon)) {
      return (
        <span className={`button__icon${iconFloat === 'right' ? ' button__icon_margin-left' : ''}`}>
          {props.icon}
        </span>
      );
    }

    if (typeof props.icon === 'string') {
      return <img src={props.icon} className={`button__icon${iconFloat === 'right' ? ' button__icon_margin-left' : ''}`} />;
    }

    return null;
  };

  return (
    <div
      {...restProps}
      onMouseDown={handleMouseDown}
      className={[animationClick ? 'button button_click' : 'button', className].join(' ')}
      style={buttonContainerInlineStyle}
      onClick={onClick}>
      <SwitchTransition mode={'out-in'}>
        <CSSTransition key={`${props.loading}`} timeout={500} classNames={'button__animation'}>
          {
            loading ? (
              <div className={'button__container'}>
                <ReactLoading type={'spin'} color={loaderColor} width={24} height={24} />
              </div>
            ) : (
              <div className={'button__container'}>
                {iconFloat === 'left' ? (
                  displayIcon()
                ) : null}
                <span
                  className={
                    uppercase
                      ? 'button__text button__text_uppercase'
                      : 'button__text'
                  }
                  style={buttonInlineStyle}>
                  {props.children}
                </span>
                {iconFloat === 'right' ? (
                  displayIcon()
                ) : null}
              </div>
            )
          }
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default Button;
