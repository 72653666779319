import { useState, useRef, useEffect } from 'react';

const useDraggableModal = ({ onClose, visible, draggable }) => {
  const [translateY, setTranslateY] = useState(0);
  const startYRef = useRef(0);
  const modalRef = useRef(null);

  const handleTouchStart = (event) => {
    startYRef.current = event.touches[0].clientY;
  };

  const handleTouchMove = (event) => {
    if (!modalRef.current) return;

    const currentY = event.touches[0].clientY;
    const deltaY = currentY - startYRef.current;

    if (deltaY > 0) {
      setTranslateY(deltaY);
    }
  };

  const handleTouchEnd = () => {
    if (!modalRef.current) return;

    const modalHeight = modalRef.current.offsetHeight;

    if (translateY > modalHeight / 2) {
      onClose && onClose();
    } else {
      modalRef.current.style.transform = 'translateY(0)';
      setTranslateY(0);
    }
    // задержка нужна чтобы полностью очищать translateY уже после того как окно было закрыто.
    // и так же чтобы была плавная анимация
    setTimeout(() => {
      setTranslateY(0);
    }, 700);

  };

  // блок скролла при открытии окна
  useEffect(() => {
    if (visible && draggable) {
      const preventScroll = (e) => e.preventDefault();
      document.body.style.overflow = 'hidden';
      document.addEventListener('touchmove', preventScroll, { passive: false });
      return () => {
        document.body.style.overflow = '';
        document.removeEventListener('touchmove', preventScroll);
      };
    }
  }, [visible]);

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.style.transform = `translateY(${translateY}px)`;
    }
  }, [translateY]);

  return {
    modalRef,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
  };
};

export default useDraggableModal;