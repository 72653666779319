import React from 'react';

import {useAppSelector, useMediaQuery} from 'utils/hooks';
import Popup from 'components/popup/Popup';

import cross from './assets/images/cross.svg';

import './MoneyTransferReject.scss';

const MoneyTransferReject = () => {
  const isMobile = useMediaQuery(768);
  
  const popup = useAppSelector(state => state.popup);

  return (
    <Popup 
      id={'transferOrderReject'} 
      onClose={popup.props.onClose} 
      mode={`${isMobile ? 'mobile_bottom-full' : 'classic'}`} 
      stylesContent={'money-transfer-reject__popup-content'}>
      {
        popup.props ? (
          <div className={'money-transfer-reject__container'}>
            <img src={cross} alt="cross" className={'money-transfer-reject__icon'} />
            <h2 className={'money-transfer-reject__title'}>
              {popup.props.error ? `${popup.props.error}` : 'Что-то пошло не так, попробуйте позже'}
            </h2>
          </div>
        ) : null
      }
    </Popup>
  );
};

export default MoneyTransferReject;
