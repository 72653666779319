import {createSlice} from '@reduxjs/toolkit';
import {Colors} from '../enums/colors';

export interface ISettingItem {
  logoIconColor: string;
  infoIcon: string;
  linkIcon: string;
  menuIconColor: string;
  enterIconColor: string;
  firstClipBoardColor: string;
  menuIconSecondaryOpacity: string;
  secondClipBoardColor: string;
  loadButtonColor: string,
  menuIconOpacity: string,
  firstColorIconIdle: string;
  firstColorIconActive: string;
  secondColorIconIdle: string;
  secondColorIconActive: string;
  firstOpacity: string;
  secondOpacity: string;
  continueIconColor: string;
  cartIconOpacity: string,
  tabMenu: {
    secondaryOpacity: string,
    mainColor: string;
    secondColor: string;
    activeMainColor: string;
    activeSecondColor: string;
    opacity: string
  };
  letter: {
    verified: string;
    noVerified: string;
  };
  orderStatusReady: {
    mainColor: string;
    opacity: string;
  };
  crossIcon: {
    mainIcon: string;
    secondIcon: string;
    opacity: string;
  };
}

export interface ISettings {
  domainNames: string[];
  allowAuth: boolean;
  currentDomainName: string;
  colors: {
    [key: string]: ISettingItem;
  };
}

const SettingSlice = createSlice({
  name: 'setting',
  initialState: {
    domainNames: [
      'mtsfirst',
      'mts-mission',
      'birthday',
      'makfa',
      'loto-pr',
      'loto',
      'rubin',
      'local',
      'megafon',
      'smeg',
      'raiffeisen',
      'asgbonus',
      'geo',
      'brucite',
      'proektirovanie',
      'sargazarm',
      'womens-day',
      'men-day',
      'aclub',
      'rus-exp',
      'leader-abc',
      'mascoglass',
      'gdnprof',
      'rtmis',
      'stg',
      'pharmacy-366',
    ],
    currentDomainName: 'default',
    colors: Colors,
    allowAuth: true,
  },
  reducers: {
    setDomainName: (state:ISettings, {payload}) => {
      const currentName = state.domainNames.find((item) => {
        if (item === 'loto-pr') {
          if (payload.includes('loto') && payload.includes('pr')) {
            return item;
          }
        }
        if (item === 'mts-mission') {
          if (payload.includes('mts') && !payload.includes('first')) {
            return item;
          }
        }
        if (item === 'rus-exp') {
          if (payload.includes('r-express')) {
            return item;
          }
        }
        if (item === 'leader-abc') {
          if (payload.includes('lider-abc')) {
            return item;
          }
        }
        if (item === 'pharmacy-366') {
          if (payload.includes('366')) {
            return item;
          }
        }
        if (payload.includes(item)) {
          return item;
        }
      });

      if (currentName) {
        state.currentDomainName = currentName;
      }
    }
  },
});
export const { setDomainName } = SettingSlice.actions;
export default SettingSlice.reducer;
