import React from 'react';

import {hidePopup, showPopup} from 'store/components/PopupSlice';
import {useAppDispatch, useMediaQuery} from 'utils/hooks';
import {closeModal} from 'utils/functions';

import Button from 'components/button/Button';
import Popup from 'components/popup/Popup';

import card from './assets/images/card.svg';

import './ContactManager.scss';

const ContactManager = () => {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(768);

  const activateCard = () => {
    dispatch(hidePopup('contactManager'));
    closeModal();
    //@ts-ignore
    jivo_api.open();
    return;
  };

  return (
    <Popup 
      id={'contactManager'}
      mode={`${isMobile ? 'mobile_bottom-full' : 'classic'}`} 
      stylesContent={'contact-manager__popup-content'}>
      <div className={'contact-manager__container'}>
        <h2 className={'contact-manager__title'}>
          Получение сертификата
        </h2>
        <p className={'contact-manager__description'}>
          Обратитесь в службу поддержки для получения сертификата
        </p>
        <div className={'contact-manager__color_red'} onClick={activateCard}>
          Поддержка
        </div>
      </div>
    </Popup>
  );
};

export default ContactManager;
