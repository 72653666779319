import React, { useEffect, useRef, useState } from 'react';

import {prepareSymbolContent} from './assets/utils/prepareSymbolContent';

import spriteIcons from '../../assets/images/spriteIcons.svg';

interface IIconSprite {
  className?: string;
  id: string;
  sprite?: string;
  onClick?: () => void;
}

const spriteCache = new Map<string, Document>();
const loadingSprites = new Map<string, Promise<Document>>();

const IconSprite: React.FC<IIconSprite> = ({ 
  id, 
  sprite = spriteIcons, 
  className, 
  onClick 
}) => {
  const [symbolContent, setSymbolContent] = useState<string | null>(null);
  const isMounted = useRef(true);
  const placeholderRef = useRef<HTMLDivElement | null>(null);

  const loadSprite = async () => {
    try {
      if (spriteCache.has(sprite)) {
        const xml = spriteCache.get(sprite);
        if (xml) prepareSymbolContent(xml, id, placeholderRef, setSymbolContent, isMounted);
        return;
      }

      if (!loadingSprites.has(sprite)) {
        const spritePromise = fetch(sprite)
          .then((res) => {
            if (!res.ok) throw new Error(`Ошибка загрузки спрайта: ${sprite}`);
            return res.text();
          })
          .then((data) => {
            const parser = new DOMParser();
            const xml = parser.parseFromString(data, 'image/svg+xml');
            spriteCache.set(sprite, xml);
            return xml;
          });

        loadingSprites.set(sprite, spritePromise);
      }

      const xml = await loadingSprites.get(sprite);
      if (xml) prepareSymbolContent(xml, id, placeholderRef, setSymbolContent, isMounted);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    isMounted.current = true;
    loadSprite();

    return () => {
      isMounted.current = false;
    };
  }, [id, sprite]);

  return (
    <div
      className={className}
      onClick={onClick}
      ref={placeholderRef}
      style={{ width: '100%' }}
      dangerouslySetInnerHTML={symbolContent ? { __html: symbolContent } : undefined}
    />
  );
};

export default IconSprite;