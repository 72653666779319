import React from 'react';

import Popup from 'components/popup/Popup';
import {useMediaQuery} from 'utils/hooks';

import image from './assets/images/StopAuth.svg';

import './DontAllowAuth.scss';

const DontAllowAuth = () => {
  const isMobile = useMediaQuery(768);
  
  return (
    <Popup 
      id={'dontAllowAuth'} 
      mode={`${isMobile ? 'mobile_bottom-full' : 'classic'}`} 
      stylesContent={'dont-allow-auth dont-allow-auth'}>
      <div className={'dont-allow-auth__container'}>
        <img src={image} alt={'image'} className={'dont-allow-auth__image'} />
        <h1 className={'dont-allow-auth__title'}>
          Ведутся технические работы
        </h1>
        <h3 className={'dont-allow-auth__subtitle'}>
          Уважаемые пользователи!
          <br/>
          В данный момент мы улучшаем функционал данной страницы.
        </h3>
        <p className={'dont-allow-auth__description'}>
          Некоторое время страница будет недоступна.
          <br/>
          Приносим свои извинения за доставленные неудобства.
        </p>
      </div>
    </Popup>
  );
};

export default DontAllowAuth;
