import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {useAppSelector, useMediaQuery} from 'utils/hooks';
import {closeModal} from 'utils/functions';

import IconSprite from 'components/IconSprite/IconSprite';
import {hidePopup} from 'store/components/PopupSlice';
import Popup from 'components/popup/Popup';

import cross from 'pages/dashboard/assets/components/rejectOrder/assets/images/cross.svg';
import like from '../completedActivationCard/assets/images/like.svg';

import './EKOPopup.scss';

const EKOPopup = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(768);
  
  const [showSupport, setShowSupport] = useState(false);
  const [desc, setDesc] = useState('');

  const support = ', обратитесь в поддержку';
  const status = useAppSelector(state => state.eko.status);
  const description = useAppSelector(state => state.eko.description) || '';

  useEffect(() => {
    if (description) {
      if (description.match(support)) {
        setDesc(description.replace(support, ''));
        setShowSupport(true);
        return;
      }
      setDesc(description);
      setShowSupport(false);
    }
  }, [description]);


  const openJivo = () => {
    // @ts-ignore
    jivo_api.open();
    dispatch(hidePopup(EKOPopup));
    closeModal();
    return false;
  };

  return (
    <Popup 
      id={'EKOPopup'}
      mode={`${isMobile ? 'mobile_bottom-full' : 'classic'}`} 
      stylesContent={'eko-popup__popup-content'}
      className={'eko-popup'}>
      {status ? (
        <div className={'eko-popup__content-container'}>
          <img src={like} alt="like" className={'completed-activation-card__icon'}/>
          <h3 className={'eko-popup__title'}>
            Активация прошла успешно
          </h3>
          <p className={'eko-popup__description'}>
            Ожидайте зачисления средств.
          </p>
        </div>
      ) : (
        <div className={'eko-popup__content-container'}>
          <IconSprite id={'cross'} className={'long-no-active__icon'} />
          <h3 className={'eko-popup__title'}>
            Ошибка активации
          </h3>
          <p className={'eko-popup__description'}>
            {desc}
            {showSupport ? (
              <>
                , обратитесь в <span onClick={openJivo} style={{color: '#4661C4', cursor: 'pointer'}}>Поддержку</span>
              </>
            ) : null}
          </p>
        </div>
      )}
    </Popup>
  );
};

export default EKOPopup;
