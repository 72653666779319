import React from 'react';
import {useSelector} from 'react-redux';

import {IStore} from 'store/store';
import {useMediaQuery} from 'utils/hooks';

import Popup from 'components/popup/Popup';
import Timer from 'components/timer/Timer';

import cross from './assets/images/cross.svg';

import './RejectOrder.scss';

const RejectOrder = () => {
  const isMobile = useMediaQuery(768);
  
  const rejectOrder = useSelector((state: IStore) => state.popup.popup.rejectOrder);

  return (
    <Popup 
      id={'rejectOrder'}
      mode={`${isMobile ? 'mobile_bottom-full' : 'classic'}`} 
      stylesContent={'reject-order__popup-content'}>
      <div className={'reject-order__container'}>
        <img src={cross} alt="cross" className={'reject-order__icon'} />
        <h2 className={'reject-order__title'}>Ошибка в заказе</h2>
        <Timer popupStatus={{rejectOrder}} />
      </div>
    </Popup>
  );
};

export default RejectOrder;