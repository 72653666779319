import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {useAppDispatch, useAppSelector} from 'utils/hooks';
import {closeModal} from 'utils/functions';

import {sendCode} from 'store/components/AuthSlice';
import {hideAllExcept, hidePopup, showPopup} from 'store/components/PopupSlice';
import {clearPurse, resetMoneyTransfer} from 'store/components/DashboardSlice';

import Input from 'components/input/Input';
import Button from 'components/button/Button';

import Timer from './assets/components/timer/Timer';

import './CodeForm.scss';

interface ICodeForm {
  phone: string;
  setShowCodeForm: any;
  setPhone: any;
  rememberCardNumber: boolean;
  acceptRules?: boolean;
  setTitle?: (T: string) => void
  authTitle?: string
  setShowPhoneInput?: (T: boolean) => void
}

const CodeForm: React.FC<ICodeForm> = ({
  phone,
  setShowCodeForm,
  setPhone,
  rememberCardNumber,
  acceptRules = true,
  setTitle,
  authTitle,
  setShowPhoneInput,
}) => {
  const dispatch = useAppDispatch();

  const history1 = useHistory();
  const test = localStorage.getItem('tester');
  const allowAuth = useAppSelector(state => state.settings.allowAuth);
  const propsPopup = useAppSelector((state) => state.popup.props);

  const [code, setCode] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [errorRepeatCode, setErrorRepeatCode] = useState<string>('');
  const [sendButton, setSendButton] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>(false);
  const [token, setToken] = useState('');
  const [resetCaptcha, setResetCaptcha] = useState(false);

  const closeCodeForm = () => {
    setPhone('');
    setShowPhoneInput && setShowPhoneInput(true);
    setCode('');
    setError('');
    setShowCodeForm(false);
  };

  const showRegisterPopup = () => {
    closeCodeForm();
    dispatch(clearPurse());
    dispatch(resetMoneyTransfer());
    dispatch(hidePopup({popup: 'auth', props: propsPopup}));
    dispatch(showPopup('register'));
  };

  const sendCodeForm = () => {
    if (!allowAuth && (test !== 'true')) {
      dispatch(hideAllExcept('dontAllowAuth'));
      return;
    }

    setLoading(true);

    if (!loading) {
      dispatch(sendCode({
        phone,
        code,
        showRegisterPopup,
        setError,
        rememberCardNumber,
      })).then(() => {
        history.pushState(null, '', '/');
        history1.location.search = '';
        setTimeout(() => setLoading(false), 500);
      });
      closeModal();
    }
  };

  useEffect( () => {
    if (code.replace('_', '').length === 4) {
      setSendButton(true);
    } else {
      setSendButton(false);
    }
  }, [code]);

  const onKeyPressHandler = (event: any) => {
    if (event.keyCode === 13) {
      sendButton && sendCodeForm();
    }
  };


  useEffect(() => {
    setTitle ? setTitle('Проверка телефона') : null;
  }, []);

  return (
    <div className={'code-form'} onKeyUp={onKeyPressHandler}>
      {!authTitle ? (
        <h3 className={'code-form__title'}>
          Проверка телефона
        </h3>
      ) : null}
      <Input
        setValue={setCode}
        value={code}
        type={'tel'}
        marginBottom={20}
        error={error}
        setError={setError}
        mask={[/\d/, /\d/, /\d/, /\d/]}
        paddingBottom={10}
        placeholder={'Код - 4 цифры'}
      />
      <p className={'code-form__number'}>
        Введите последние 4 цифры с входящего звонка, который поступит в течение минуты на&nbsp;
        <span className={'code-form__number_non-wrap'}>{phone}</span>
      </p>
      <Button
        disabled={!sendButton && !acceptRules}
        width={'100%'}
        marginBottom={20}
        loading={loading}
        onClick={sendCodeForm}>
        Проверить код и войти
      </Button>
      {
        errorRepeatCode ? (
          <div className={'code-form__error'}>{errorRepeatCode}</div>
        ) : null
      }
      <Timer phone={phone} setError={setErrorRepeatCode} />
      <a href="#" onClick={closeCodeForm} className={'code-form__change-number'}>Изменить номер</a>
    </div>
  );
};

export default CodeForm;
