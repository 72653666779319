import React from 'react';

import {IActivationHistory} from 'store/components/DashboardSlice';

import './ActivationHistoryItem.scss';

interface IActivationHistoryItem {
  item: IActivationHistory
}

const ActivationHistoryItem = ({item}: IActivationHistoryItem) => {
  const date = item.expired_at
    ? item.expired_at.split('-')[0]
    : 'бессрочно';

  return (
    <div className={'activation-history-item'}>
      <div className={'activation-history-item__container activation-history-item__container_desktop'}>
        <div className={'activation-history-item__number activation-history-item__text'}>
          {item.number}
        </div>
        <div className={'activation-history-item__info'}>
          <div className={'activation-history-item__nominal activation-history-item__text'}>
            {item.nominal} ₽
          </div>
          <div className={'activation-history-item__date activation-history-item__text'}>
            {item.activation_date}
          </div>
          <div className={'activation-history-item__expired activation-history-item__text'}>
            {date}
          </div>
        </div>
      </div>
      <div className={'activation-history-item__container activation-history-item__container_mobile'}>
        <div>
          <div className={'activation-history-item__content'}>
            <div>
              <h3 className={'activation-history-item__title'}>Номер сертификата</h3>
              <span>{item.number}</span>
            </div>
            <div>
              <h3 className={'activation-history-item__title'}>Номинал</h3>
              <span>{item.nominal} ₽</span>
            </div>
          </div>
          <div>
            <div className={'activation-history-item__date_mobile'}>Дата активации: {item.activation_date}</div>
            <div>Срок действия баланса: {date}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivationHistoryItem;
